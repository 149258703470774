import React, { useState, useEffect, useRef } from 'react'
import { BodyContainerDashboard, ContainerDashboard, DashboardContainerRows, GuriButton } from '../../commons/common.styled';
import { connect } from 'react-redux';
import { Title } from '../../commons/typography.styled';
import colors from '../../constants/colors';
import { CompaniesTable, MainRowTable, MainColumnTable, MainHeadTable, BodyTable, BodyRowTable, BodyColumnTable, BtnCompanyTable, CompaniesTableContainer } from '../companies/companies.styled';
import Menu from '../../components/menu/Menu';
import { getCompanies } from '../../requests/companies';
import AddCompany from './AddCompany';

const Companies = (props) => {
  let _isMounted = useRef(false);
  const [companies, setCompanies] = useState([])
  const [status, setStatus] = useState('none')

  useEffect(() => {
    _isMounted.current = true;
    _isMounted && getCompaniesMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_isMounted])

  const getCompaniesMethod = () => {
    props.func_verify((user) => {
      getCompanies(user.accessToken)
        .then(response => {
          if (response.status !== 200) {
            throw new Error("Ocurrió un error al iniciar sesión.")
          }
          return response
        })
        .then((res) => res.json())
        .then(res => setCompanies(res))
        .catch((error) => { })
    })
    return
  }

  const toogleModal = () => {
    setStatus(status === 'none' ? 'flex' : 'none')
  }

  return (
    <>
      <DashboardContainerRows>
        <Menu logout={props.dispatch} />
        <BodyContainerDashboard>
          <ContainerDashboard>
            <Title color={colors.darkBlue} fontSize="24px">Compañías
            <GuriButton onClick={() => toogleModal()}>Añadir nueva compañia</GuriButton>
            </Title>
            <CompaniesTableContainer>
              <CompaniesTable>
                <MainHeadTable>
                  <MainRowTable>
                    <MainColumnTable>NOMBRE</MainColumnTable>
                    <MainColumnTable>CORREO</MainColumnTable>
                    <MainColumnTable>SOCIO</MainColumnTable>
                    <MainColumnTable>USUARIOS</MainColumnTable>
                    <MainColumnTable>DETALLES</MainColumnTable>
                  </MainRowTable>
                </MainHeadTable>
                <BodyTable>
                  {Object.keys(companies).map((el, id) => {
                    const company = companies[el]
                    return (
                      <BodyRowTable key={id}>
                        <BodyColumnTable whiteSpace="nowrap">{company.name}</BodyColumnTable>
                        <BodyColumnTable background={true}>{company.email}</BodyColumnTable>
                        <BodyColumnTable whiteSpace="nowrap">{company.is_partner ? 'Sí' : 'No'}</BodyColumnTable>
                        <BodyColumnTable background={true}>{company.admins.length}</BodyColumnTable>
                        <BodyColumnTable background={true}>
                          {company.admins = []}
                          <BtnCompanyTable to={{ pathname: `/companies/${el}`, company, }}>Ver</BtnCompanyTable>
                        </BodyColumnTable>
                      </BodyRowTable>
                    )
                  })}
                  {
                    companies.length === 0 && (
                      <BodyRowTable>
                        <BodyColumnTable colSpan="5" textAlign="center">No hay registros</BodyColumnTable>
                      </BodyRowTable>
                    )
                  }
                </BodyTable>
              </CompaniesTable>
            </CompaniesTableContainer>
          </ContainerDashboard>
        </BodyContainerDashboard>
      </DashboardContainerRows>
      <AddCompany 
        saveCompany={[companies, setCompanies]}
        func_verify={props.func_verify}
        status={status}
        toogleModal={toogleModal}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  notification: state.notification,
})


export default connect(mapStateToProps)(Companies)