
import styled, { css } from 'styled-components';

export const Title = styled.h1`
  font-size: ${props => (props.fontSize || '38px')};
  font-family: 'Roboto', sans-serif;
  line-height: 1.1em;
  color: ${props => (props.color || 'black')};
  margin: ${props => (props.margin || '0')};
  font-weight: ${props => (props.fontWeight || '600')};
  ${props => props.sm && css`
    font-size: ${props.sm};
  `}
  ${props => props.fullSize && css`
    width: 100%;
  `}
`;

export const Text = styled.p`
font-family: muli;
font-weight: ${props => (props.fontWeight || '400')};
font-size: ${props => (props.fontSize || '16px')};
text-align: ${props => (props.textAlign || 'left')};
color: ${props => (props.color || 'black')};
margin: ${props => (props.margin || '0')};
font-style: ${props => (props.fontStyle || 'normal')};
border: ${props => (props.border)};
padding: ${props => (props.padding)};
border-radius: ${props => (props.borderRadius)};
${props => props.lineHeight && css`
  line-height: ${props.lineHeight};
`}

${props => props.maxWidth && css`
  max-width: ${props.maxWidth};
`}

${props => props.width && css`
  flex: 0 0 ${props.width};
  width: ${props.width};
`}
`;

export const TextGrotesque = styled(Text)`
font-family: galano;
`;

export const TextNexa = styled(Text)`
font-family: nexa;
`;