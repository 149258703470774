import styled, { css } from 'styled-components';
import colors from '../../constants/colors';
import mediaQueries from '../../constants/mediaQueries';

export const FormPolicy = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const GuriButton = styled.button`
  background: ${props => (props.background || colors.blue)};;
  padding: 10px 15px;
  color: ${colors.white};
  border: 0;
  border-radius: 2px;
  margin: 5px 0;
  cursor: pointer;
  ${props => props.disabled && css`
    cursor: not-allowed;
  `}
`;

export const PolicyDetails = styled.div`

`;

export const HeaderContaienr = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

export const LogOutBtn = styled.a`
  color: ${colors.blue};
`;
export const DashboardContainer = styled.div`
padding: 0 20px;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
background: ${colors.blueBackground};
min-height: 100vh;
img.logo{
  align-self: flex-start;
  width: 100px;
  margin-top: 20px;
}
img.ilus{
  width: 700px;
  height: auto;
  margin: 30px 0 0;
}
`;

export const SocialLinks = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin: 50px 0;
a{
  margin: 0 10px;
  img{
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
  }
}
`;

export const GuriDashboardContainer = styled.div`
display: flex;
// flex-wrap: wrap;
justify-content: space-between;
overflow: visible;
padding: 0;
${mediaQueries.md}{
  flex-direction: column;
}
`;

export const DashboardCard = styled.div`
width: 50%;
display: flex;
flex-direction: column;
margin: ${props => (props.margin || '0')};
${mediaQueries.md}{
  // flex-direction: column;
  width: 100%;
  flex: 0 0 100%;
}
${mediaQueries.sm}{
  ${props => props.sm && css`
    ${props.sm.fullWidth && css`
      width: 100%;
      flex: 0 0 100%;
    `}
    justify-content: ${props.sm.justifyContent || props.justifyContent || 'flex-start'};
  `}
}
&:first-child {
  margin-right: 20px
}
`;

export const DashboardCardBody = styled.div`
padding: ${props => (props.padding || '20px')};
background: ${props => (props.background || colors.white)};
margin: ${props => (props.margin || '10px 0')};
${props => props.column && css`
  display: flex;
  flex-direction: column;
`}
`;

export const InformationCard = styled.div`
margin: 20px 0;
display: flex;
flex: 1;
flex-direction: column;
h1{
  padding: 10px;
  border: 1px solid ${colors.blueBg};
}
`;

export const RowInformation = styled.div`
display: flex;
flex: 1;
justify-content: space-between;
padding: 10px 0;
margin: 5px 0;
border-bottom: 1px solid ${colors.bluedGray};
flex-wrap: wrap;
`;

export const GroupInformation = styled.div`
display: flex;
div:not(:only-child) {
  padding-right: 10px;
  :last-child {
    padding-right: 0;
  }
}
div:not(:first-child){
  padding-left: 10px;
  border-left: 1px solid ${colors.bluedGray};
}
`;

export const ContactContainer = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const ButtonContact = styled.a`
  background: ${colors.blueBtn};
  padding: 5px 10px;
  border-radius: 5px;
  color: ${colors.blue};
  text-decoration: none;
  margin-right: 10px;
  font-weight: 600;
`;

export const GuriInput = styled.input`
  min-width: 60%;
  text-align: right;
  border: 1px solid;
  padding: 10px;
  margin: 5px;
  ${props => props.disabled && css`
    border: 0;
    background: none;
  `}
`;

export const IllustrationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const IllustrationGuri = styled.img`
  width: 40%;
`;

export const IllustrationIcon = styled.img`
  width: 15px;
`;

export const DownloadPolicy = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin: 1em 0;
`;

export const PDFSvg = styled.img`
  width: 30px;
  margin-right: 10px;
`;

export const ContainerFile = styled.div`
  ${props => props.state === true && css`
    border: 1px dashed black;
    margin: 10px 0;
    padding: 10px;
  `}
`;

export const DestroyDocument = styled.button`
  display: none;
  color: ${colors.red};
  font-size: 12px;
  background: none;
  border: 0;
  cursor: pointer;
  text-decoration-line: underline;
  ${props => props.state === true && css`
    display: block;
  `}
`;

export const InputUploadDocument = styled.input`
  display: none;
  ${props => props.state === true && css`
    display: block;
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Button = styled.button`
  background: ${props => props.background ? props.background : colors.blueLight};
  color: ${props => props.color ? props.color : colors.white};
  border: 0;
  flex: 0 0 50%;
  margin: 10px 5px;
  padding: 10px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  ${props => props.outline && css`
    background: none;
    border: 2px solid ${props => props.background ? props.background : colors.blueLight};
  `}
`;

export const SelectStatus = styled.select`
  padding: 5px 10px;
  margin-left: 10px;
  border: 1px solid ${(props) => {
    if (props.policyStatus === 'pending') {
      return colors.yellow
    } else if (props.policyStatus === 'approved') {
      return colors.green
    } else if (props.policyStatus === 'unfinished') {
      return colors.orange
    } else {
      return colors.red
    }
  }}
`

export const StatusContainer = styled.div`
  display: flex;
  margin: 5px 0;
`;