import styled, { css } from 'styled-components';
import colors from '../../constants/colors';
import mediaQueries from '../../constants/mediaQueries';



export const DashboardCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${props => (props.margin || '0')};
  ${mediaQueries.md}{
    // flex-direction: column;
    width: 100%;
    flex: 0 0 100%;
  }
  ${mediaQueries.sm}{
    ${props => props.sm && css`
      ${props.sm.fullWidth && css`
        width: 100%;
        flex: 0 0 100%;
      `}
      justify-content: ${props.sm.justifyContent || props.justifyContent || 'flex-start'};
    `}
  }
  &:first-child {
    margin-right: 20px
  }
`;

export const DashboardCardBody = styled.div`
  padding: ${props => (props.padding || '20px')};
  background: ${props => (props.background || colors.white)};
  margin: ${props => (props.margin || '10px 0')};
  ${props => props.column && css`
    display: flex;
    flex-direction: column;
  `}
`;

export const RowInformation = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 10px 0;
  margin: 5px 0;
  border-bottom: 1px solid ${colors.bluedGray};
  flex-wrap: wrap;
`;

export const GroupInformation = styled.div`
  display: flex;
  div:not(:only-child) {
    padding-right: 10px;
    :last-child {
      padding-right: 0;
    }
  }
  div:not(:first-child){
    padding-left: 10px;
    border-left: 1px solid ${colors.bluedGray};
  }
`;

export const CompanyDetails = styled.div`

`;

export const InformationCard = styled.div`
  margin: 20px 0 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  h1{
    padding: 10px;
    border: 1px solid ${colors.blueBg};
  }
`;