import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import IlusHombre from '../../assets/images/ilus_hombre.svg'
import { AuthenticateContainer, AuthenticateInnerContainer, AuthenticateNavbar, AuthenticateForm, AuthenticateFormDescription, AuthenticateFormInputs, GuriFormElement } from './SetPassword.styled';
import { TextNexa } from '../../commons/typography.styled';
import colors from '../../constants/colors';
import { GuriButton } from '../../commons/common.styled';
import { createPassword } from '../../requests/admins';
import { InputGuri } from '../../components/inputs/inputs';

const SetPassword = (props) => {
  const idAdmin = props.match.params.id;
  const [adminToken, setAdminToken] = useState({token: ""})

  const {
    history,
  } = props;

  const emptyData = {
    email: '',
    password: '',
    passwordConfirmation: '',
  };
  const [registerErrors, setRegisterErrors] = useState({ ...emptyData });
  const [registerData, setRegisterData] = useState({ ...emptyData });

  useEffect(() => {
    if (props.location && props.location.search) setAdminToken(queryStringParse(props.location.search))
  }, [props])

  const queryStringParse = (string) => {
    let parsed = {}
    if (string !== '') {
      string = string.substring(string.indexOf('?') + 1)
      let p1 = string.split('&')
      p1.forEach(function (value) {
        let params = value.split('=')
        parsed[params[0]] = params[1]
      });
    }
    return parsed
  }

  const validateKey = (key, value) => {
    if (!value.trim()) {
      setRegisterErrors(errors => ({
        ...errors,
        [key]: 'Campo requerido',
      }))
      return false;
    }
    setRegisterErrors(errors => ({
      ...errors,
      [key]: '',
    }))

    return true;
  };

  const handleTextInputChange = (event) => {
    const { id: field, value } = event.target;
    setRegisterData({
      ...registerData,
      [field]: value,
    });
    validateKey(field, value);
  };

  const hadleRegister = async () => {
    for (const key in registerData) {
      if (!validateKey(key, registerData[key])) {
        return;
      }
    }

    if (registerData.password !== registerData.passwordConfirmation) {
      setRegisterErrors(errors => ({
        ...errors,
        passwordConfirmation: 'Contraseñas no son iguales',
      }));
      return;
    }

    const { passwordConfirmation, password } = registerData;
    createPassword(idAdmin, JSON.stringify({
      adminToken: adminToken.token,
      password,
      passwordConfirmation,
    }))
      .then(async (res) => {
        if (res.status !== 200) {
          res.json()
            .then((res) => { throw new Error(res.msg) })
            .catch((err) => alert(err.message))
        } else {
          await localStorage.removeItem('redux')
          history.push('/login');
        }
      })
      .catch((err) => alert(err.message))
  }

  return (
    <AuthenticateContainer>
      <AuthenticateInnerContainer>
        <AuthenticateNavbar>
          <a href="/">
            <img src={Logo} alt="logo" width="60" />
          </a>
        </AuthenticateNavbar>
        <AuthenticateForm alignItems="initial" minHeight="calc(100% - 160px)">
          <AuthenticateFormDescription
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <TextNexa
              fontSize="32px"
              color={colors.blueBg}
              lineHeight="45px"
              textAlign="center"
              sm={{
                fontSize: '20px',
                margin: '20px',
                lineHeight: '28px'
              }}
            >
              Crea tu contraseña e ingresa al panel de administración Güri
            </TextNexa>
            <img src={IlusHombre} alt="ilus hombre" />
          </AuthenticateFormDescription>
          <AuthenticateFormInputs>
            <GuriFormElement fullWidth justifyContent="center">
              <InputGuri
                id="email"
                name="email"
                label="Correo electrónico"
                placeholder="Correo electrónico"
                title="Correo electrónico"
                margin="0"
                value={registerData.email}
                onChange={handleTextInputChange}
                errorMessage={registerErrors.email}
                maxWidth="450px"
                isInAuthenticate
              />
            </GuriFormElement>
            <GuriFormElement fullWidth justifyContent="center">
              <InputGuri
                id="password"
                name="password"
                label="Crear Contraseña"
                title="Crear Contraseña"
                margin="0"
                placeholder="Crear Contraseña"
                value={registerData.password}
                onChange={handleTextInputChange}
                errorMessage={registerErrors.password}
                maxWidth="450px"
                isInAuthenticate
                type="password"
              />
            </GuriFormElement>
            <GuriFormElement fullWidth justifyContent="center">
              <InputGuri
                id="passwordConfirmation"
                name="passwordConfirmation"
                label="Verificar Contraseña"
                title="Verificar Contraseña"
                margin="0"
                placeholder="Verificar Contraseña"
                value={registerData.passwordConfirmation}
                onChange={handleTextInputChange}
                errorMessage={registerErrors.passwordConfirmation}
                maxWidth="450px"
                isInAuthenticate
                type="password"
              />
            </GuriFormElement>
            <GuriButton
              onClick={hadleRegister}
              maxWidth="450px"
              color={colors.white}
              textcolor={'#fffff'}
              margin="0px auto"
              height="50px"
              uppercase
              rectangle
              fullWidth
              sm={{
                width: '100%',
                padding: '0'
              }}
            >
              Crear contraseña
            </GuriButton>
          </AuthenticateFormInputs>
        </AuthenticateForm>
      </AuthenticateInnerContainer>
    </AuthenticateContainer>
  );
};

export default withRouter(SetPassword);
