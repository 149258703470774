import { URL_API } from './index';

function getAnalytics(token, companyID = false ) {
  let url = companyID ? `${URL_API}/dashboard/dashboard/analytics?companyID=${companyID}` :
            `${URL_API}/dashboard/dashboard/analytics`
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'JWT ' + token,
    }
  })
    .then(res => res)
    .catch(err => err)
}

export { getAnalytics }