import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalContent } from '../../components/modals/modals.styled';
import { AddAdminForm } from './AddPromoter.styled';
import { InputGuri, SelectGuri } from '../../components/inputs/inputs';
import { getCompanies } from '../../requests/companies';
import { postAdmin } from '../../requests/admins';
const AddAdmin = (props) => {
  let _isMounted = useRef(false);
  const [data, setData] = useState({});
  const [companies, setCompanies] = useState({});

  useEffect(() => {
    _isMounted.current = true;
    _isMounted && getCompaniesMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_isMounted])

  const getCompaniesMethod = () => {
    props.func_verify((user) => {
      getCompanies(user.accessToken)
        .then(response => {
          if (response.status !== 200) {
            throw new Error("Ocurrió un error al iniciar sesión.")
          }
          return response
        })
        .then((res) => res.json())
        .then(res => setCompanies(res))
        .catch((error) => { })
    })
    return
  }
  const changeInput = (ev) => {
    let prevData = data;
    prevData[ev.target.name] = ev.target.value;
    setData(prevData);
  }

  const saveForm = (ev) => {
    ev.preventDefault();
    saveAdmin();
  }

  const saveAdmin = () => {
    props.func_verify((user) => {
      postAdmin(user.accessToken, JSON.stringify(data))
        .then(response => {
          if (response.status !== 201) {
            throw new Error("Ocurrió un error al iniciar sesión.")
          }
          return response
        })
        .then((res) => res.json())
        .then(res => {
          let prevAdmins = props.saveAdmin[0];
          prevAdmins.push(res.data);
          props.saveAdmin[1]([...prevAdmins]);
          props.toogleModal();
        })
        .catch((error) => {
          console.log(error)
          alert("Hubo un error al crear el admin.")
        })
    })
    return
  }

  return (
    <Modal display={props.status}>
      <ModalBody>
        <ModalHeader>
          <h2>Añadir administrador</h2>
          <span onClick={() => props.toogleModal()}>Cerrar</span>
        </ModalHeader>
        <ModalContent>
          <AddAdminForm
            method="POST"
            onSubmit={(ev) => saveForm(ev)}
          >
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="name"
              placeholder="Nombre"
              title="Nombre"
              margin="0"
              type="text"
              autocomplete="off"
            />
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="lastname"
              placeholder="Apellido"
              title="Apellido"
              margin="0"
              type="text"
              autocomplete="off"
            />
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="email"
              placeholder="Email"
              title="Email"
              margin="0"
              type="email"
            />
            <SelectGuri
              onChange={(ev) => { changeInput(ev) }}
              name="is_team"
              placeholder="¿Es equipo administrador (Sólo equipo Güri)?"
              title="¿Es equipo administrador (Sólo equipo Güri)?"
              margin="0"
            >
              <option>Escoge una opción</option>
              <option value="true">Sí, es administrador</option>
              <option value="false">No, no es administrador</option>
            </SelectGuri>
            <SelectGuri
              onChange={(ev) => { changeInput(ev) }}
              name="is_promoter"
              placeholder="¿Es promotor?"
              title="¿Es promotor?"
              margin="0"
            >
              <option>Escoge una opción</option>
              <option value="true">Sí, es promotor</option>
              <option value="false">No, no es promotor</option>
            </SelectGuri>
            <SelectGuri
              onChange={(ev) => { changeInput(ev) }}
              name="company"
              placeholder="Compañía asignada."
              title="Compañía asignada"
              margin="0"
            >
              <option>Escoge una opción</option>
              {
                Object.keys(companies).map((el, id) => {
                  return (<option key={id} value={el}>{companies[el].name}</option>)
                })
              }
            </SelectGuri>
            <InputGuri
              type="submit"
              name="submit"
              value="Guardar"
              margin="1em 0"
            />
          </AddAdminForm>
        </ModalContent>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  notification: state.notification,
})


export default connect(mapStateToProps)(AddAdmin)