import React from 'react';
import { InputGuriElement, InputContainer, InputTitle, SelectGuriElement, ErrorText } from './inputs.styled';
import colors from '../../constants/colors';

const InputGuri = (props) => {
  return (
    <InputContainer width={props.width} margin={props.margin}>
      <Title title={props.title} />
      <InputGuriElement {...props} />
      {
        props.errorMessage && (<ErrorText
          fontSize="10px"
          color={colors.red}
        >
          {props.errorMessage}
        </ErrorText>)
      }
    </InputContainer>
  );
}

const SelectGuri = (props) => {
  return (
    <InputContainer width={props.width} margin={props.margin}>
      <Title title={props.title} />
      <SelectGuriElement {...props}>
        {props.children}
      </SelectGuriElement>
    </InputContainer>
  )
}

const Title = (props) => {
  if (props.title) {
    return (
      <InputTitle margin="0">{props.title}:</InputTitle>
    )
  }
  return null
}

export { InputGuri, SelectGuri };