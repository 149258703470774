export function login(accessToken, expiry, email, name, lastName, isTeam, isPromoter, company) {
  return { type: 'LOG_IN',  accessToken, expiry, email, name, lastName, isTeam, isPromoter, company};
}

export function logout() {
  return { type: 'LOG_OUT' }
}

export function refresh_token(next_func) {
  return { type: 'REFRESH_TOKEN', next_func}
}

export function clear_refresh_token(user) {
  return { type: 'CLEAR_REFRESH_TOKEN', ...user }
}