import styled from 'styled-components';
import colors from '../../constants/colors';
import { Link } from 'react-router-dom';

export const MenuContainer = styled.section`
  background: ${colors.white};
  padding: 2em 1em 1em;
  height: calc(100vh - 3em);
  width: 15%;
  display: flex;
  position: fixed;
  flex-direction: column;
`;

export const Nav = styled.nav`
  list-style-type: none;
`;

export const Li = styled.li`
  list-style-type: none;
  margin: 1rem 0;
`;

export const Ancla = styled(Link)`
  color: ${colors.blue};
  text-decoration: none;
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0 0;
  flex: 1;
  justify-content: space-between;
`;
