import styled from 'styled-components';
import colors from '../../constants/colors';

export const AnalyticsCards = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.margin ? props.margin : '0'};
`;

export const Card = styled.div`
  background: ${props => props.background ? props.background : colors.white};
  border-radius: 5px;
  flex: 1;
  padding: 10px;
  box-shadow: 0px 2px 10px -5px ${colors.grayedBlue};
  margin: 10px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const TableAnalytic = styled.table`
  display: flex;
  flex: 1;
  margin: 10px 0;
`;
export const TableTBodyAnalytic = styled.tbody`
  flex: 1;
`;
export const TableTrAnalytic = styled.tr`
  display: flex;
  flex: 1;
  font-size: 14px;
  margin: 5px 0;
`;
export const TableTdAnalytic = styled.td`
  flex: 1;
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
`;