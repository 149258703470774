import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { DashboardContainerRows, BodyContainerDashboard, ContainerDashboard } from '../../commons/common.styled';
import Menu from '../../components/menu/Menu';
import { Title, Text } from '../../commons/typography.styled';
import colors from '../../constants/colors';
import { AnalyticsCards, Card, TableAnalytic, TableTBodyAnalytic, TableTrAnalytic, TableTdAnalytic } from './Analytics.styled';
import { formatNumber } from '../../utils/utils';
import { getAnalytics } from '../../requests/analytics';
import moment from 'moment';

const Analytics = (props) => {
  let _isMounted = useRef(false)
  const [totalSales, setTotalSales] = useState(0);
  const [averagePolicy, setAveragePolicy] = useState(0);
  const [countPolicies, setCountPolicies] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [mensPerncentage, setMensPercentage] = useState(0);
  const [womensPercentage, setWomensPercentage] = useState(0);
  const [anualRecurrency, setAnualRecurrency] = useState(0);
  const [monthlyRecurrency, setMonthlyRecurrency] = useState(0);
  const [debitPercentage, setDebitPercentage] = useState(0);
  const [creditPercentage, setCreditPercentage] = useState(0);
  const [statesRates, setStatesRates] = useState([]);
  const [ageRate, setAgeRate] = useState({
    '18 - 24': 0,
    '25 - 34': 0,
    '35 - 49': 0,
  })
  const [salesMonth, setSalesMonth] = useState([]);

  useEffect(() => {
    _isMounted.current = true;
    _isMounted && getAnalyticsMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAnalyticsMethod = () => {
    props.func_verify((user) => {
      getAnalytics(user.accessToken, user.isPromoter && user.company)
        .then(response => {
          if (response.status !== 200) {
            throw new Error("Ocurrió un error al iniciar sesión.")
          }
          return response
        })
        .then((res) => res.json())
        .then(({ data }) => {
          console.log(data)
          setTotalSales(data.totalSales);
          setAveragePolicy(data.averagePolicy);
          setCountPolicies(data.countPolicies);
          setConversionRate(data.conversionRate);
          setMensPercentage(data.genderPercentage.mens);
          setWomensPercentage(data.genderPercentage.womens);
          setAnualRecurrency(data.recurrencePercentage.anual);
          setMonthlyRecurrency(data.recurrencePercentage.monthly);
          setDebitPercentage(data.percentagePaymentsMethods.debit);
          setCreditPercentage(data.percentagePaymentsMethods.credit);
          setStatesRates(data.statesRate);
          setAgeRate(data.ageRate);
          setSalesMonth(data.salesMonth);
        })
        .catch((error) => { console.log(error) })
    })
    return
  }

  return (
    <DashboardContainerRows>
      <Menu logout={props.dispatch} />
      <BodyContainerDashboard>
        <ContainerDashboard>
          <Title color={colors.darkBlue} fontSize="24px">Informes y estadísticas</Title>
          <AnalyticsCards margin="1em 0 0">
            <Card>
              <Text fontWeight="600" fontSize="14px">Ventas totales</Text>
              <Text fontSize="24px" fontWeight="bold" margin="10px 0">{formatNumber(totalSales)} MXN</Text>
            </Card>
            <Card>
              <Text fontWeight="600" fontSize="14px">Póliza promedio</Text>
              <Text fontSize="24px" fontWeight="bold" margin="10px 0">{formatNumber(averagePolicy)} MXN</Text>
            </Card>
            <Card>
              <Text fontWeight="600" fontSize="14px">Total de pólizas</Text>
              <Text fontSize="24px" fontWeight="bold" margin="10px 0">{countPolicies}</Text>
            </Card>
          </AnalyticsCards>
          <AnalyticsCards>
            <Card>
              <Text fontWeight="600" fontSize="14px">Tasa de conversión</Text>
              <Text fontSize="24px" fontWeight="bold" margin="10px 0">{conversionRate}%</Text>
            </Card>
            <Card>
              <Text fontWeight="600" fontSize="14px">Edad (años)</Text>
              <TableAnalytic>
                <TableTBodyAnalytic>
                  {
                    Object.keys(ageRate).map((value, index) => {
                      return (
                        <TableTrAnalytic>
                          <TableTdAnalytic>{value}</TableTdAnalytic>
                          <TableTdAnalytic textAlign="right">{ageRate[value]}%</TableTdAnalytic>
                        </TableTrAnalytic>
                      )
                    })
                  }
                </TableTBodyAnalytic>
              </TableAnalytic>
            </Card>
            <Card>
              <Text fontWeight="600" fontSize="14px">Sexo</Text>
              <TableAnalytic>
                <TableTBodyAnalytic>
                  <TableTrAnalytic>
                    <TableTdAnalytic>Mujer</TableTdAnalytic>
                    <TableTdAnalytic textAlign="right">{womensPercentage}%</TableTdAnalytic>
                  </TableTrAnalytic>
                  <TableTrAnalytic>
                    <TableTdAnalytic>Hombre</TableTdAnalytic>
                    <TableTdAnalytic textAlign="right">{mensPerncentage}%</TableTdAnalytic>
                  </TableTrAnalytic>
                </TableTBodyAnalytic>
              </TableAnalytic>
            </Card>
          </AnalyticsCards>

          <AnalyticsCards>
            <Card>
              <Text fontWeight="600" fontSize="14px">Estado</Text>
              <TableAnalytic>
                <TableTBodyAnalytic>
                  {
                    statesRates.map((state, index) => (
                      <TableTrAnalytic key={index}>
                        <TableTdAnalytic>{state.state}</TableTdAnalytic>
                        <TableTdAnalytic textAlign="right">{state.percentage}%</TableTdAnalytic>
                      </TableTrAnalytic>
                    ))
                  }
                </TableTBodyAnalytic>
              </TableAnalytic>
            </Card>
            <Card>
              <Text fontWeight="600" fontSize="14px">Recurrencia y Forma de pago</Text>
              <TableAnalytic>
                <TableTBodyAnalytic>
                  <TableTrAnalytic>
                    <TableTdAnalytic>Anual</TableTdAnalytic>
                    <TableTdAnalytic textAlign="right">{anualRecurrency}%</TableTdAnalytic>
                  </TableTrAnalytic>
                  <TableTrAnalytic>
                    <TableTdAnalytic>Mensual</TableTdAnalytic>
                    <TableTdAnalytic textAlign="right">{monthlyRecurrency}%</TableTdAnalytic>
                  </TableTrAnalytic>
                </TableTBodyAnalytic>
              </TableAnalytic>
              <TableAnalytic>
                <TableTBodyAnalytic>
                  <TableTrAnalytic>
                    <TableTdAnalytic>Crédito</TableTdAnalytic>
                    <TableTdAnalytic textAlign="right">{creditPercentage}%</TableTdAnalytic>
                  </TableTrAnalytic>
                  <TableTrAnalytic>
                    <TableTdAnalytic>Débito</TableTdAnalytic>
                    <TableTdAnalytic textAlign="right">{debitPercentage}%</TableTdAnalytic>
                  </TableTrAnalytic>
                </TableTBodyAnalytic>
              </TableAnalytic>
            </Card>
            <Card>
              <Text fontWeight="600" fontSize="14px">Ventas por mes</Text>
              <TableAnalytic>
                <TableTBodyAnalytic>
                  {
                    salesMonth.map((month, index) => {
                      const date = new Date(month.date)
                      return (
                        <TableTrAnalytic key={index}>
                          <TableTdAnalytic>{moment(date).format("MMMM yy")}</TableTdAnalytic>
                          <TableTdAnalytic textAlign="right">{formatNumber(month.value)} MXN</TableTdAnalytic>
                        </TableTrAnalytic>
                      )
                    })
                  }
                </TableTBodyAnalytic>
              </TableAnalytic>
            </Card>
          </AnalyticsCards>
        </ContainerDashboard>
      </BodyContainerDashboard>
    </DashboardContainerRows>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  notification: state.notification,
})


export default connect(mapStateToProps)(Analytics)