import { connect } from 'react-redux';
import * as actions from '../actions/userActions';
import React, {useState} from 'react';
import { AuthenticateContainer, AuthenticateInnerContainer, AuthenticateNavbar, AuthenticateForm, AuthenticateFormDescription, AuthenticateFormInputs, GuriFormElement } from './set_password/SetPassword.styled';
import Logo from './../assets/images/logo.svg';
import IlusHombre from './../assets/images/ilus_hombre.svg'
import { TextNexa } from './../commons/typography.styled';
import colors from './../constants/colors';
import { InputGuri } from './../components/inputs/inputs';
import { login } from '../requests/auth';
import { GuriButton } from './../commons/common.styled';

const Login = (props) => {

  const emptyData = {
    email: '',
    password: '',
  };
  const [registerErrors, setRegisterErrors] = useState({ ...emptyData });
  const [registerData, setRegisterData] = useState({ ...emptyData });

  const handleTextInputChange = (event) => {
    const { id: field, value } = event.target;
    setRegisterData({
      ...registerData,
      [field]: value,
    });
    validateKey(field, value);
  };

  const validateKey = (key, value) => {
    if (!value.trim()) {
      setRegisterErrors(errors => ({
        ...errors,
        [key]: 'Campo requerido',
      }))
      return false;
    }
    setRegisterErrors(errors => ({
      ...errors,
      [key]: '',
    }))

    return true;
  };

  const handleLogin = async () => {

    console.log("HOLaaa")

    login(JSON.stringify(registerData))
    .then((res) => {
      if (res.status !== 202) throw Error('Verifica tus credenciales.')
      return res
    })
    .then(res => res.json())
    .then(response => {
      const accessToken = response.access_token;
      const expiry = response.expiresIn;
      const email = response.admin.email;
      const name = response.admin.name;
      const lastName = response.admin.lastname;
      const isTeam = response.admin.isTeam;
      const isPromoter = response.admin.isPromoter;
      const company = response.admin.company;
      props.history.push('/polizas');
      return props.dispatch(actions.login(accessToken, expiry, email, name, lastName, isTeam, isPromoter, company));
    })
    .catch((err) => {
      setRegisterErrors(errors => ({
        ...errors,
        email: err.message,
      }))
    })
  }


  return (
    <AuthenticateContainer>
      <AuthenticateInnerContainer>
        <AuthenticateNavbar>
          <a href="/">
            <img src={Logo} alt="logo" width="60" />
          </a>
        </AuthenticateNavbar>
        <AuthenticateForm alignItems="initial" minHeight="calc(100% - 160px)">
          <AuthenticateFormDescription
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <TextNexa
              fontSize="32px"
              color={colors.blueBg}
              lineHeight="45px"
              textAlign="center"
              sm={{
                fontSize: '20px',
                margin: '20px',
                lineHeight: '28px'
              }}
            >
              Bienvenido al panel de administración
            </TextNexa>
            <img src={IlusHombre} alt="ilus hombre" />
          </AuthenticateFormDescription>
          <AuthenticateFormInputs>
            <GuriFormElement fullWidth justifyContent="center">
              <InputGuri
                id="email"
                name="email"
                label="Correo electrónico"
                placeholder="Correo electrónico"
                title="Correo electrónico"
                margin="0"
                type="email"
                onChange={handleTextInputChange}
                errorMessage={registerErrors.email}
                maxWidth="450px"
                isInAuthenticate
              />
            </GuriFormElement>
            <GuriFormElement fullWidth justifyContent="center">
              <InputGuri
                id="password"
                type="password"
                name="password"
                label="Crear Contraseña"
                title="Crear Contraseña"
                margin="0"
                placeholder="Crear Contraseña"
                onChange={handleTextInputChange}
                errorMessage={registerErrors.password}
                maxWidth="450px"
                isInAuthenticate
              />
            </GuriFormElement>
            <GuriButton
              onClick={handleLogin}
              maxWidth="450px"
              color={colors.white}
              textcolor={'#fffff'}
              margin="0px auto"
              height="50px"
              uppercase
              rectangle
              fullWidth
              sm={{
                width: '100%',
                padding: '0'
              }}
            >
              Iniciar sesión
            </GuriButton>
          </AuthenticateFormInputs>
        </AuthenticateForm>
      </AuthenticateInnerContainer>
    </AuthenticateContainer>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Login);