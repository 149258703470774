import React from 'react';
import RefreshLogin from './components/refreshLogin/RefreshLogin';

function App(props) {
  return (
    <React.Fragment>
      <RefreshLogin/>
      {props.children}
    </React.Fragment>
  );
}

export default App;
