import { URL_API } from './index';

async function getAdmins(token) {
  try {
    const res = await fetch(`${URL_API}/dashboard/admin/admins`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function postAdmin(token, body) {
  try {
    const res = await fetch(`${URL_API}/dashboard/admin/register`, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function createPassword(adminID, body) {
  try {
    const res = await fetch(`${URL_API}/dashboard/admin/${adminID}/set_password`, {
      method: 'PUT',
      body,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function deleteAdmin(token, adminID, body) {
  try {
    const res = await fetch(`${URL_API}/dashboard/admin/${adminID}`, {
      method: 'DELETE',
      body,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

export { getAdmins, postAdmin, createPassword, deleteAdmin };