import React, { Component } from 'react';

import {
  BrowserRouter as ReactRouter,
  Route,
} from 'react-router-dom';
import App from './App';
import Login from './pages/Login';
import { refresh_token } from './actions/userActions';
import { add_notification } from './actions/notificationActions';
// import { INIT_FUNC_VERIFY_AUTH } from './actions/verifyActions';
import { connect } from 'react-redux';
import Policies from './pages/policies/Policies';
import Policy from './pages/policies/Policy';
import PoliciesAccepted from './pages/policies/PoliciesAccepted';
import Analytics from './pages/analytics/Analytics';
import Companies from './pages/companies/Companies';
import Promoters from './pages/promoters/Promoters';
import Company from './pages/companies/Company';
import SetPassword from './pages/set_password/SetPassword';
import NewPolicy from './pages/policies/NewPolicy';
class Router extends Component {
  constructor(props) {
    super(props);
    const user = props.user;
    this.state = {
      userSignedIn: user && user.accessToken ? user.userSignedIn : false,
    }
    this.userLogOutTime = this.userLogOutTime.bind(this);
    // this.verifyAuthTime = this.verifyAuthTime.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const prevUser = prevProps.user;
    const user = this.props.user;
    if (prevUser.userSignedIn !== user.userSignedIn) {
      this.setState({ userSignedIn: user.userSignedIn });
    }
  }

  userLogOutTime() {
    this.props.dispatch(add_notification("notice", "Por seguridad hemos cerrado tu sesión. Inicia nuevamente."));
    this.setState({ userLogOutTime: true });
  }


  verifyAuthTime(func) {
    if (this.user.expiry !== undefined) {
      const nowDate = new Date();
      if (parseInt(this.user.expiry) <= parseInt(nowDate.getTime() / 1000)) {
        this.dispatch(refresh_token(func));
        return;
      } else {
        func(this.user)
      }
    }
  }

  home() {
    if (!this.state.userSignedIn) {
      return (
        <>
          <Route exact path="/" component={Login}></Route>
          <Route path="/login" component={Login}></Route>
        </>
      );
    }
    return (
      <>
        <Route exact path="/" component={(routeprops) => (<Analytics {...routeprops} func_verify={this.verifyAuthTime} />)} />
        <Route exact path="/polizas" component={(routeprops) => (<Policies {...routeprops} func_verify={this.verifyAuthTime} />)} />
        <Route exact path="/polizas-aceptadas" component={(routeprops) => (<PoliciesAccepted {...routeprops} func_verify={this.verifyAuthTime} />)} />
        <Route path="/polizas/:id" render={(routeprops) => (<Policy {...routeprops} func_verify={this.verifyAuthTime} />)} />
        <Route exact path="/companies" component={(routeprops) => (<Companies {...routeprops} func_verify={this.verifyAuthTime} />)} />
        <Route exact path="/companies/:id" component={(routeprops) => (<Company {...routeprops} func_verify={this.verifyAuthTime} />)} />
        <Route exact path="/partners" component={(routeprops) => (<Promoters {...routeprops} func_verify={this.verifyAuthTime} />)} />
        <Route exact path="/solicitudes" component={(routeprops) => (<NewPolicy {...routeprops} func_verify={this.verifyAuthTime} />)} />
      </>
    );
  }


  render() {
    return (
      <ReactRouter>
        <App>
          {this.home()}
          <Route exact path="/users/:id/set_password" component={(routeprops) => (<SetPassword  {...routeprops}/>)} />
        </App>
      </ReactRouter>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    verify_auth: state.verify,
  }
}

export default connect(mapStateToProps)(Router);