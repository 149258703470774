const getAge = (dateOfBirth) => {
  var today = new Date();
  var birthDate = new Date(dateOfBirth);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const openTab = (url) => {
  const tab = window.open(url, '_blank');
  if (window.focus) tab.focus();
  return tab;
}

export const formatNumber = (num = 0) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  
  return formatter.format(num);
};

export { getAge, openTab }