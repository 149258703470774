import React from 'react';
import { DashboardContainerRows, ContainerDashboard, BodyContainerDashboard } from '../../commons/common.styled';
import Menu from '../../components/menu/Menu';
import { Title } from '../../commons/typography.styled';
import colors from '../../constants/colors';
import styled from 'styled-components';
import { connect } from 'react-redux';

const NewPolicy = props => {
  if(!props.user && !props.user.company) alert("Hubo un error al cargar la información necesaria. Por favor intenta borrando la caché del navegar e iniciar sesión nuevamente. Si el error persiste, contáctanos.")
  return (
    <DashboardContainerRows>
      <Menu logout={props.dispatch} />
      <BodyContainerDashboard>
        <ContainerDashboard padding="2em 2% 0" height="calc(100vh - 2em)">
          <Title color={colors.darkBlue} fontSize="24px">Crear solicitud</Title>
          <ContainerNewPolicy style={{height: '90%', marginTop: '1em'}}>
            <iframe title="newPolicy" height="100%" width="100%" src={`${process.env.REACT_APP_DASHBOARD_ENDPOINT}/quote/init?company=${props.user.company}`} frameBorder="0"></iframe>
          </ContainerNewPolicy>
        </ContainerDashboard>
      </BodyContainerDashboard>
    </DashboardContainerRows>
  )
}

const ContainerNewPolicy = styled.section`
  border: 1px solid ${colors.darkBlue};
`;

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(NewPolicy);