export default function notificationReducer(state = [], action) {
  switch(action.type) {
    case 'ADD_NOTIFICATION':
      return [...state, action.payload]
    case 'REMOVE_NOTIFICATION':
      const newStateNot = [...state];
      newStateNot.splice(action.index, 1);
      return newStateNot;
    default:
      return state;
  }
}