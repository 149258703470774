import { URL_API } from './index';

async function getCompanies(token) {
  try {
    const res = await fetch(`${URL_API}/dashboard/companies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function getCompany(token, id) {
  try {
    const res = await fetch(`${URL_API}/dashboard/companies/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function saveCompanyAPI(token, data) {
  try {
    const res = await fetch(`${URL_API}/dashboard/companies`, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

export { getCompanies, getCompany, saveCompanyAPI };