import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Menu from '../../components/menu/Menu';
import { ContainerDashboard, DashboardContainerRows, BodyContainerDashboard } from '../../commons/common.styled';
import { Title, Text } from '../../commons/typography.styled';
import colors from '../../constants/colors';
import { getCompany } from '../../requests/companies';
import { DashboardCard, DashboardCardBody, RowInformation, GroupInformation, InformationCard } from './Company.styled';
import PromotersList from '../../components/promoters/PromotersList';

const Company = (props) => {
  const idCompany = useRef(props.match.params.id);
  const [company, setCompany] = useState({});

  useEffect(() => {
    if (props.location.company) {
      setCompany(props.location.company);
    }
    if (true) {
      props.func_verify((user) => {
        getCompany(user.accessToken, idCompany.current)
          .then(response => {
            if (response.status !== 200) {
              throw new Error("Ocurrió un error al iniciar sesión.")
            }
            return response
          })
          .then((res) => res.json())
          .then((res) => {
            setCompany(res.data);
          })
          .catch((error) => { })
      })
    }
  }, [props])

  return (
    <>
      <DashboardContainerRows>
        <Menu logout={props.dispatch} />
        <BodyContainerDashboard>
          <ContainerDashboard>
            <DashboardCard>
              <DashboardCardBody>
                <DashboardCardInformation
                  title={`${company.name}`}
                  key={1}
                  attributes={[
                    {
                      title: 'Email:',
                      type: 'email',
                      disabled: true,
                      value: company.email ? company.email : '',
                      formatted: company.email ? company.email : '',
                    },
                    {
                      title: 'Agente',
                      value: [
                        {
                          title: 'Agente:',
                          type: 'text',
                          disabled: true,
                          value: company.agent_name ? company.agent_name : '',
                          formatted: company.agent_name ? company.agent_name : '',
                        },
                        {
                          title: 'Contrato:',
                          type: 'text',
                          disabled: true,
                          value: company.agent_cua ? company.agent_cua : '',
                          formatted: company.agent_cua ? company.agent_cua : '',
                        },
                        {
                          title: 'DA:',
                          type: 'text',
                          disabled: true,
                          value: company.agent_da ? company.agent_da : '',
                          formatted: company.agent_da ? company.agent_da : '',
                        },
                        {
                          title: 'Distribución:',
                          type: 'text',
                          disabled: true,
                          value: company.agent_distribution ? company.agent_distribution : '',
                          formatted: company.agent_distribution ? company.agent_distribution : '',
                        },
                      ]
                    },
                    {
                      title: '',
                      value: [
                        {
                          title: 'Creado:',
                          type: 'text',
                          disabled: true,
                          value: company.createdAt ? company.createdAt : '',
                          formatted: company.createdAt ? company.createdAt : '',
                        },
                        {
                          title: 'Última actualización:',
                          type: 'text',
                          disabled: true,
                          value: company.updatedAt ? company.updatedAt : '',
                          formatted: company.updatedAt ? company.updatedAt : '',
                        }
                      ]
                    }
                  ]}
                />
              </DashboardCardBody>
              <DashboardCardBody>
                <InformationCard>
                  <DashboardCardInformation 
                    title="Usuarios"
                    attributes={[]}
                  />
                  <PromotersList admins={company.admins} func_verify={props.func_verify} />
                </InformationCard>
              </DashboardCardBody>
            </DashboardCard>

          </ContainerDashboard>
        </BodyContainerDashboard>
      </DashboardContainerRows>
    </>
  );

}


const DashboardCardInformation = ({ title, attributes, state, updateFields }) => {
  const showTitle = title ? (
    <Title
      fontSize="1rem"
      color={colors.blueBg}
      margin="0 0 15px"
    >
      {title}
    </Title>
  ) : (null);

  const showColumns = (data, index) => {
    if (typeof data.value != "object") {
      return (
        <RowInformation key={index}>
          <Text color={colors.gray} fontWeight="bold">{data.title}</Text>
          {
            data.type === 'file' && data.value ? <a href={data.value} target="_blank" rel="noopener noreferrer">Abrir archivo</a> : <Text color={colors.black} fontWeight="bold">{data.formatted || "- - -"}</Text>
          }
        </RowInformation>
      )
    } else if (typeof data.value == "object") {
      return (
        <GroupInformation key={index}>
          {data.value.map((data, index) => {
            return showColumns(data, index)
          })}
        </GroupInformation>
      )
    }
  }

  return (
    <InformationCard>
      {showTitle}
      {attributes.map((data, index) => {
        return showColumns(data, index)
      })}
    </InformationCard>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  notification: state.notification,
})


export default connect(mapStateToProps)(Company)
