import { URL_API } from './index';

async function login(data) {
  try {
    const res = await fetch(`${URL_API}/dashboard/admin/login`, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

export { login };