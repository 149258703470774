import React, { useState, useEffect } from 'react';
import { RefreshLoginModal, RefresLoginContainer, RefreshLoginLogo, RefreshLoginErrors } from './RefeshLogin.styled';
import logo from '../../assets/images/logo.svg';
import * as actions from '../../actions/userActions';
import { login } from '../../requests/auth';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputGuri } from '../inputs/inputs';
import { GuriFormElement } from '../../pages/set_password/SetPassword.styled';
import { GuriButton } from '../../commons/common.styled';
import colors from '../../constants/colors';

const RefreshLogin = (props) => {
  let next_func;
  let refreshToken = props.user && props.user.refreshToken ? props.user.refreshToken.status : false;
  const [errors, setErrors] = useState([{ msg: 'Para continuar es necesario ingresar nuevamente tus credenciales.' }])
  const [status, setStatus] = useState(refreshToken);
  const [data, setData] = useState({
    email: '',
    password: ''
  })

  useEffect(() => {
    props.user.refreshToken && setStatus(props.user.refreshToken.status)
  }, [props.user.refreshToken])

  const handleTextInputChange = (event) => {
    const { id: field, value } = event.target;
    setData({
      ...data,
      [field]: value,
    });
  };

  const reqAuth = () => {
    login(JSON.stringify(data))
      .then(response => {
        if (response.status === 404) {
          throw new Error("Email or password invalid")
        } else if (response.status !== 202) {
          throw new Error("Ocurrió un error al iniciar sesión.")
        }
        return response
      })
      .then(res => res.json())
      .then(response => {
        if (props.user.refreshToken.next_func) {
          next_func = props.user.refreshToken.next_func;
        }
        const accessToken = response.access_token;
        const expiry = response.expiresIn;
        const email = response.admin.email;
        const name = response.admin.name;
        const lastName = response.admin.lastname;
        const isTeam = response.admin.isTeam;
        const isPromoter = response.admin.isPromoter;
        const company = response.admin.company;
        props.dispatch(actions.login(accessToken, expiry, email, name, lastName, isTeam, isPromoter, company));
        if (next_func) {
          let newDataUser = {
            accessToken: accessToken,
            email: email,
            name: name,
            lastName: lastName,
            expiry: expiry,
            isTeam, 
            isPromoter,
            company
          }
          next_func.forEach(func => {
            func(newDataUser);
          });
          props.dispatch(actions.clear_refresh_token(newDataUser));
        }
        return
      })
      .catch((res) => {
        setErrors([{ msg: res.message }])
      })
  }

  return (
    <RefreshLoginModal style={{ display: status ? (status ? "flex" : "none") : "none" }} >
      <RefresLoginContainer>
        <RefreshLoginLogo src={logo} />
        {errors.map((error, index) => (
          <RefreshLoginErrors key={index}>
            <p>{error.msg}</p>
          </RefreshLoginErrors>
        ))}
        <div>
          <GuriFormElement fullWidth justifyContent="center" paddingBottom="5px">
              <InputGuri
                id="email"
                name="email"
                label="Correo electrónico"
                placeholder="Correo electrónico"
                title="Correo electrónico"
                margin="0"
                type="email"
                onChange={handleTextInputChange}
                maxWidth="450px"
              />
            </GuriFormElement>
            <GuriFormElement fullWidth justifyContent="center" paddingBottom="5px">
              <InputGuri
                id="password"
                name="password"
                label="Contraseña"
                placeholder="Contraseña"
                title="Contraseña"
                type="password"
                onChange={handleTextInputChange}
                margin="0"
                maxWidth="450px"
              />
            </GuriFormElement>
            <GuriButton
              onClick={reqAuth}
              maxWidth="450px"
              color={colors.white}
              textcolor={'#fffff'}
              margin="10px 0px auto"
              height="50px"
              uppercase
              rectangle
              fullWidth
              sm={{
                width: '100%',
                padding: '0'
              }}
            >
              Iniciar sesión
            </GuriButton>
        </div>
      </RefresLoginContainer>
    </RefreshLoginModal>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(withRouter(RefreshLogin));