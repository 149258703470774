export const createGNPinsurancePDF = (quote, application, company = false) => {
    let documentURL = 'https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=76cb4956-9a10-4e3a-8da2-4808d390f8e6&env=na4&acct=098915bc-3c8f-475a-b67d-d018e397e731';
    if (company && company.is_partner) documentURL = 'https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=afe18387-fbfc-4d0d-af31-86537e34cf46&env=na4&acct=098915bc-3c8f-475a-b67d-d018e397e731'

    const regexReplace = /%0A%20%20%20%20/g;
    var date = new Date();

    const makeBeneficiaries = (beneficiaries, address) => {
        let urlBeneficiaries = '';
        for (let index = 0; index < beneficiaries.length; index++) {
            const beneficiary = `&ben${index + 1}Paterno=${beneficiaries[index].lastname}&ben${index + 1}Materno=${beneficiaries[index].secondLastname}&ben${index + 1}Nombres=${beneficiaries[index].name}&ben${index + 1}Parentesco=${beneficiaries[index].relationship}&ben${index + 1}Porcentaje=${beneficiaries[index].percentage}&ben${index + 1}Day=${beneficiaries[index].dueDate.split('-')[2]}&ben${index + 1}Month=${beneficiaries[index].dueDate.split('-')[1]}&ben${index + 1}Year=${beneficiaries[index].dueDate.split('-')[0]}&ben${index + 1}Adress=${address}`;
            urlBeneficiaries += beneficiary;
        }
        return urlBeneficiaries;
    }

    const makeAgents = () => {
        let stringUrl = '';
        if (company && company.is_partner) {
            stringUrl += `&CUA_Agente=${company.agent_cua}`
            stringUrl += `&Contrato_Agente=${company.agent_contrato}`
            stringUrl += `&DA_Agente=${company.agent_da}`
            stringUrl += `&Nombre_Agente=${company.agent_name}`
            stringUrl += `&Distribucion_Agente=${company.agent_distribution}`
            stringUrl += `&agente_Email=${company.email}`
            stringUrl += `&agente_UserName=${company.agent_name.replace(' ', '')}`
            // stringUrl += `&guri_UserName=${"Daniela Haddad Beltrán".replace(' ', '')}`
            // stringUrl += `&agente_Email=solicitud@getguri.com`
        } else {
            stringUrl += `&CUA_Agente=27104`
            stringUrl += `&Contrato_Agente=001`
            stringUrl += `&DA_Agente=785`
            stringUrl += `&Nombre_Agente=Daniela Haddad Beltrán`
            stringUrl += `&Distribucion_Agente=100`
        }
        return stringUrl
    }

    const makeReferences = (references) => {
        let urlReferences = ''
        for (let index = 0; index < references.length; index++) {
            const reference = `&ref${index + 1}Name=${references[index].name}&ref${index + 1}Phone=${references[index].phone} `;
            urlReferences += reference;
        }
        return urlReferences;
    }

    const fullDocument =
        `&client_UserName=${quote.name.replace(' ', '')}
    &client_Email=${ quote.email}
    ${ makeAgents()}
    &dayToday=${ date.getDate()}
    &monthToday=${ date.getMonth() + 1}
    &yearToday=${ date.getFullYear()}
    &paterno=${ application[0].lastname}
    &materno=${ application[0].secondLastname}
    &names=${ application[0].name}
    &dayBirth=${ application[0].dateOfBirth.split('-')[2]}
    &monthBirth=${ application[0].dateOfBirth.split('-')[1]}
    &yearBirth=${ application[0].dateOfBirth.split('-')[0]}
    &rfcLetters=${ application[0].rfc !== '' ? application[0].rfc.slice(0, -9) : ''}
    &rfcYear=${ application[0].rfc !== '' ? application[0].rfc.slice(4, -7) : ''}
    &rfcMonth=${ application[0].rfc !== '' ? application[0].rfc.slice(6, -5) : ''}
    &rfcDay=${ application[0].rfc !== '' ? application[0].rfc.slice(8, -3) : ''}
    &rfcHomoclave=${ application[0].rfc !== '' ? application[0].rfc.slice(-3) : ''}
    &curp=${ application[0].CURP}
    &gender=${ quote.gender}
    &maritalStatus=${ application[0].maritalStatus}
    &bornCountry=${ application[0].country}
    &bornCity=${ application[0].birthCity}
    &nationality=${ application[0].nationality}
    &occupation=${ application[0].occupation}
    &domStreet=${ application[1].street}
    &domExterior=${ application[1].exterior}
    &domInterior=${ application[1].interior}
    &domDelegacion=${ application[1].delegacion}
    &domZip=${ application[1].zip}
    &domColonia=${ application[1].colonia}
    &domCity=${ application[1].city}
    &domState=${ application[1].state}
    &domCountry=${ application[1].country}
    &domLada=${ quote.lada || ''}
    &domPhone=${ quote.phone || ''}
    &workName=${ application[0].companyName}
    &workPhone=${ application[0].workPhone}
    &workSalary=${ application[0].monthlyIncome}
    &workAdress=${ application[0].workAddress}
    &workType=${ application[0].businessActivity}
    &workPosition=${ application[0].job + ' ' + application[0].activities}
    &workFire=${ application[0].occupationRisksSpecifications ? application[0].occupationRisksSpecifications : 'No'}
    &govermentPos=${ application[0].governmentPosition}
    &govermentDesc=${ application[0].governmentPositionSpecifications}
    &domFiscStreet=${ quote.typeInvestment === "retirement" ? application[1].street : ""}
    &domFiscExterior=${ quote.typeInvestment === "retirement" ? application[1].exterior : ""}
    &domFiscInterior=${ quote.typeInvestment === "retirement" ? application[1].interior : ""}
    &domFiscDelegacion=${ quote.typeInvestment === "retirement" ? application[1].delegacion : ""}
    &domFiscState=${ quote.typeInvestment === "retirement" ? application[1].state : ""}
    &domFiscColonia=${ quote.typeInvestment === "retirement" ? application[1].colonia : ""}
    &domFiscCity=${ quote.typeInvestment === "retirement" ? application[1].city : ""}
    &domFiscZip=${ quote.typeInvestment === "retirement" ? application[1].zip : ""}
    &domFiscLada=${ quote.typeInvestment === "retirement" ? quote.lada || '' : ""}
    &domFiscPhone=${ quote.typeInvestment === "retirement" ? quote.phone || '' : ""}
    &plazoPago=${
        quote.typeInvestment === "retirement" ? (65 - (date.getFullYear() - Number(application[0].dateOfBirth.split('-')[0]))) :
            quote.typeInvestment === "ten_years" ? "10 años" :
                quote.typeInvestment === "fifteen_years" ? "15 años" :
                    quote.typeInvestment === "twenty_years" ? "20 años" :
                        "X"
        }
    &ise=${ quote.disabilityProtection ? 'x' : ''}
    &bit=${ quote.disabilityProtection ? 'x' : ''}
    &liquidacion=${ quote.howReceive === 'Pago mensual' ? 'rentas' : 'unico'}
    &iseActive=${ quote.disabilityProtection ? 'x' : ''}
    &primaDeducible=${ quote.typeInvestment === "retirement" ? (quote.fiscalBenefit ? 'Si' : 'No') : undefined}
    &pagoPeriodo=${ quote.paymentFrecuency === 'Anual' ? 'Anual' : 'Mensual'}
    &SuperSA=$ ${ quote.cotizacion.SA}
    &FallecimientoSA=$ ${ quote.cotizacion.SA}
    &InvalidezSA=${ quote.disabilityProtection ? '$ ' + quote.cotizacion.SA : ''}
    &alcoholIngest=${ application[2].alcohol}
    &alcoholClass=${ application[2].alcoholSpecsType}
    &alcoholQuantity=${ application[2].alcoholSpecsQuantity}
    &alcoholFrecuency=${ application[2].alcoholSpecsFrecuency}
    &alcoholSince=${ application[2].alcohol === 'Si' ? application[2].alcoholSpecsSince : ''}
    &drugsIngest=${ application[2].drugs}
    &drugsClass=${ application[2].drugsSpecsType}
    &drugsQuantity=${ application[2].drugsSpecsQuantity}
    &drugsFrecuency=${ application[2].drugsSpecsFrecuency}
    &drugsSince=${ application[2].drugs === 'Si' ? application[2].drugsSpecsSince : ''}
    &tobacoIngest=${ quote.iAm === 'Fumador' ? 'Si' : 'No'}
    &tobacoClass=${ quote.iAm === 'No fumador' ? '' : quote.cigar === 'Si' ? 'Cigarrillos' : quote.otherType}
    &tobacoQuantity=${ quote.iAm === 'Fumador' ? quote.quantity : ''}
    &tobacoFrecuency=${ quote.iAm === 'Fumador' ? quote.quantity + ' al mes' : ''}
    &tobacoSince=${ quote.iAm === 'Fumador' ? quote.since : ''}
    &descriptionAllDrugs=${ application[2].drugsAlcoholPast === '' ? 'Nunca' : application[2].drugsAlcoholPast}
    &userWeight=${ application[2].weight}
    &userHeight=${ application[2].height}
    &instructionsPilot=${ application[2].pilot}
    &penalAntecedents=${ application[2].criminalRecord}
    &penalAntecedentsDes=${ application[2].criminalRecordSpecifications}
    &insureRejected=${ application[2].insuranceRejected}
    &insureRejectedDes=${ application[2].insuranceRejectedSpecifications}
    &insureRamo=${ application[2].currentlyInsured}
    &insureRamoDes=${ application[2].currentlyInsuredSpecifications}
    &familyDiabetes=${ application[2].familyDiabetes}
    &familyDiabetesDes=${ application[2].familyDiabetesSpecifications}
    &surgery=${ application[2].surgery}
    &surgeryDes=${ application[2].surgerySpecifications}
    &hepatic=${ application[2].criticalDisease}
    &hepaticDes=${ application[2].criticalDiseaseSpecifications}
    &treatment=${ application[2].unreferredCriticalDisease}
    &treatmentDes=${ application[2].unreferredCriticalDiseaseSpecifications}
    &motocycle=${ application[2].moto}
    &motocycleDes=${ application[2].motoSpecifications}
    &aeronaves=${ application[2].privateAircrafts}
    &aeronavesDes=${ application[2].privateAircraftsSpecifications}
    &sport=${ application[2].professionalSport}
    &sportDes=${ application[2].professionalSportSpecifications}
    &debitoClabe=${ application[3].cardType === 'Débito' ? application[3].CLABE : ''}
    &tddCardNumber=${ application[3].cardType === 'Débito' ? application[3].tddCardNumber : ''}
    &debitoMonth=${ application[3].cardType === 'Débito' ? application[3].expirationDate.split('/')[0] : ''}
    &debitoYear=${ application[3].cardType === 'Débito' ? application[3].expirationDate.split('/')[1] : ''}
    &creditoBank=${ application[3].cardType === 'Crédito' ? application[3].bank : ''}
    &creditoNo=${ application[3].cardType === 'Crédito' ? application[3].cardNumber : ''}
    &creditoMonth=${ application[3].cardType === 'Crédito' ? application[3].expirationDate.split('/')[0] : ''}
    &creditoYear=${ application[3].cardType === 'Crédito' ? application[3].expirationDate.split('/')[1] : ''}
    &cardType=${ application[3].cardType}
    &idType=${ application[3].idType}
    &idEntity=${ application[3].emisor}
    &idNumber=${ application[3].idNumber}
    &CoberturasPlan=${ quote.typeInvestment === "retirement" ? 'Consolida PPR' : 'Trasciende'}
    &CoberturasPlazo=${ quote.typeInvestment === "retirement" ? 'EA 65 años' : '100 años'}
    &Cobertura=${ quote.typeInvestment === "retirement" ? 'II' : '100 años'}
    ${ makeBeneficiaries(application[2].beneficiaries, 'MISMO')}
    ${ makeReferences(application[2].references)}
    `;

    const url = encodeURI(documentURL + fullDocument);
    return url.replace(regexReplace, '');
}