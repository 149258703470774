const colors = {
  ultraDarkBlue: '#011929',
  darkBlue: '#003D7C',
  blueBg: '#1B3565',
  blue: '#043D78',
  lightBlue: '#6AA2CC',
  blueLight: '#017afe',
  grayedBlue: '#466275',
  ultraLightBlue: '#DDEBF5',
  gray: '#7F7F7F',
  bluedGray: '#AEBAC2',
  lightBluedGray: '#DDEBF5',
  lightGray: '#CECECE',
  yellow: '#FFCD00',
  darkYellow: '#FFCE00',
  lightYellow: '#FFE99D',
  red: '#FF0000',
  white: '#FFFFFF',
  black: '#000000',
  green: 'green',
  blueBackground: '#F5F8FA',
  orange: '#FFA500',
};

export default colors;