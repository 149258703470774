import styled from 'styled-components';
import colors from '../../constants/colors';

export const Modal = styled.div`
  display: ${(props) => props.display || 'flex'};
  flex: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  background: hsl(0deg 0% 21% / 66%);
  align-items: center;
  justify-content: center;
`;

export const ModalBody = styled.div`
  min-width: 800px;
  max-width: 800px;
  min-height: 400px;
  max-height: 90vh;
  background: white;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;
export const ModalHeader = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 1em;
  span {
    cursor: pointer;
    text-decoration: underline;
    color: ${colors.blue};
  }
`;

export const ModalContent = styled.div`
  overflow-y: auto;
  padding: 1em;
  overflow-x: hidden;
  flex: 1;
  max-width: 100%;

`;