import { URL_API } from './index';

async function getPolicies(token, companyID = false ) {
  let url = companyID ? `${URL_API}/users/applications?companyID=${companyID}` :
            `${URL_API}/users/applications`
  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function getPoliciesAccepted(token, companyID = false) {
   let url = companyID ? `${URL_API}/users/applications-accepted?companyID=${companyID}` :
            `${URL_API}/users/applications-accepted`
  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function getPolicy(token, id) {
  try {
    const res = await fetch(`${URL_API}/users/policy/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function updatePolicies(id, token, body) {
  try {
    const res = await fetch(`${URL_API}/users/policy/${id}`, {
      method: 'PUT',
      body,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function deleteDocument(id, token, field) {
  try {
    const res = await fetch(`${URL_API}/users/policy/${id}/remove_document`, {
      method: 'DELETE',
      body: JSON.stringify({
        field,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function upDocument(id, token, body) {
  try {
    const res = await fetch(`${URL_API}/users/policy/${id}/add_document`, {
      method: 'PUT',
      body,
      headers: {
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

async function sendMailWelcomeAccepted(id, token) {
  try {
    const res = await fetch(`${URL_API}/users/policy/${id}/send_welcome_mail`, {
      method: 'PUT',
      body: '',
      headers: {
        'Authorization': 'JWT ' + token,
      }
    });
    return res;
  }
  catch (err) {
    return err;
  }
}

export { getPolicies, getPoliciesAccepted, updatePolicies, getPolicy, deleteDocument, upDocument, sendMailWelcomeAccepted };