import styled from 'styled-components';
import colors from '../../constants/colors';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GuriLogo = styled.img`
  max-width: 100px;
  height: 100%;
`;

export const UserHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  span {
    text-decoration: underline;
  }
`;

export const LogOutBtn = styled.button`
  background: transparent;
  color: black;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  font-size: 16px;
  color: ${colors.darkBlue};
`;