import React, { useState, useEffect, useRef } from 'react'
import { BodyContainerDashboard, ContainerDashboard, DashboardContainerRows, GuriButton } from '../../commons/common.styled';
import { connect } from 'react-redux';
import { Title } from '../../commons/typography.styled';
import colors from '../../constants/colors';
import Menu from '../../components/menu/Menu';
import { getAdmins } from '../../requests/admins';
import AddPromoter from './AddPromoter';
import PromotersList from '../../components/promoters/PromotersList';

const Promoters = (props) => {
  let _isMounted = useRef(false);
  const [admins, setAdmins] = useState([])
  const [status, setStatus] = useState('none')

  useEffect(() => {
    _isMounted.current = true;
    _isMounted && getAdminsMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_isMounted])

  const getAdminsMethod = () => {
    props.func_verify((user) => {
      getAdmins(user.accessToken)
        .then(response => {
          if (response.status !== 200) {
            throw new Error("Ocurrió un error al iniciar sesión.")
          }
          return response
        })
        .then((res) => res.json())
        .then(res => setAdmins(res))
        .catch((error) => { })
    })
    return
  }

  const toogleModal = () => {
    setStatus(status === 'none' ? 'flex' : 'none')
  }

  return (
    <>
      <DashboardContainerRows>
        <Menu logout={props.dispatch} />
        <BodyContainerDashboard>
          <ContainerDashboard>
            <Title color={colors.darkBlue} fontSize="24px">
              Promotores
              <GuriButton onClick={() => toogleModal()}>Añadir nuevo administrador</GuriButton>
            </Title>
            <PromotersList admins={admins} func_verify={props.func_verify} />
          </ContainerDashboard>
        </BodyContainerDashboard>
      </DashboardContainerRows>
      <AddPromoter
        saveAdmin={[admins, setAdmins]}
        func_verify={props.func_verify}
        status={status}
        toogleModal={toogleModal}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  notification: state.notification,
})


export default connect(mapStateToProps)(Promoters)