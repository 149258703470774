export default function userReducer(state = {}, action) {
  switch(action.type) {
    case 'LOG_IN':
      return { 
        accessToken: action.accessToken,
        email: action.email,
        name: action.name,
        lastName: action.lastName,
        expiry: action.expiry,
        userSignedIn: true,
        isTeam: action.isTeam,
        isPromoter: action.isPromoter,
        company: action.company,
        refreshToken: {
          status: false,
          next_func: [],
        },
      }
    case 'LOG_OUT':
      console.log("HOALAA")
      return {
        userSignedIn: false,
        refreshToken: {
          status: false,
          next_func: [],
        },
      }
    case 'REFRESH_TOKEN':
      let newNextFunc = [...state.refreshToken.next_func, action.next_func];
      return {
        userSignedIn: true,
        refreshToken: {
          status: true,
          next_func: newNextFunc,
        },
      }
    case 'CLEAR_REFRESH_TOKEN':
      return {
        accessToken: action.accessToken,
        email: action.email,
        name: action.name,
        lastName: action.lastName,
        expiry: action.expiry,
        userSignedIn: true,
        isTeam: action.isTeam,
        isPromoter: action.isPromoter,
        company: action.company,
        refreshToken: {
          status: false,
          next_func: [],
        },
      }
    default:
      return state;
  }
};