import styled from 'styled-components';
import colors from '../constants/colors';

export const DashboardContainerRows = styled.div`
  display: flex;
`

export const Container = styled.div`
  width: calc(100% - 4%);
  padding: 2rem 2%;
  max-width: 1280px;
  margin: 0 auto;
`;

export const BodyContainerDashboard = styled.div`
  background: ${colors.ultraLightBlue};
  min-height: 100vh;
  width: calc(100% - 15%);
  left: 15%;
  position: absolute;
`;

export const ContainerDashboard = styled.div`
  padding: ${props => props.padding || '2rem 2%'} ;
  border-radius: 5px;
  height: ${props => props.height || ''};
`;

export const Card = styled.div`
  background: ${colors.white};
`;

export const RowCard = styled.div`
  display: flex;
  justify-content: ${props => (props.justifyContent || 'space-between')};
  padding: ${props => (props.padding || '1rem')};
  margin: ${props => (props.margin || '0.7rem 0')};
`;

export const GuriButton = styled.button`
  background: ${props => props.background || colors.blue};
  margin: ${props => props.margin || '1em'};
  color: ${props => props.color || colors.white};
  border: ${props => props.border || 'none'};
  padding: ${props => props.padding || '0.5em'};
  outline: none;
  border-radius: ${props => props.borderRadius || '5px'};
  cursor: ${props => props.cursor || 'pointer'};
`;