import React, { useState, useEffect, useRef } from 'react'
import { BodyContainerDashboard, ContainerDashboard, DashboardContainerRows } from '../../commons/common.styled';
import { connect } from 'react-redux';
import { Title, Text } from '../../commons/typography.styled';
import colors from '../../constants/colors';
import { PoliciesTable, MainRowTable, MainColumnTable, MainHeadTable, BodyTable, BodyRowTable, BodyColumnTable, BtnPolicyTable, PoliciesTableContainer, ListTypeInvestment, TypeInvestment } from './policies.styled';
import { formatNumber } from '../../utils/utils';
import Menu from '../../components/menu/Menu';
import moment from 'moment';
import { getPoliciesAccepted } from '../../requests/policies';

const PoliciesAccepted = (props) => {
  let _isMounted = useRef(false);
  const [policies, setPolicies] = useState([])
  const [messageLoading, setMessageLoading] = useState('Cargando pólizas ⏱');

  useEffect(() => {
    _isMounted.current = true;
    _isMounted && getPoliciesMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_isMounted])

  const getPoliciesMethod = () => {
    props.func_verify((user) => {
      getPoliciesAccepted(user.accessToken, user.isPromoter && user.company)
        .then(response => {
          if (response.status !== 200) {
            throw new Error("Ocurrió un error al iniciar sesión.")
          }
          return response
        })
        .then((res) => res.json())
        .then(res => setPolicies(res.reverse()))
        .then(() => setMessageLoading("Sin pólizas"))
        .catch((error) => { })
    })
    return
  }

  return (
    <DashboardContainerRows>
      <Menu logout={props.dispatch} />
      <BodyContainerDashboard>
        <ContainerDashboard>
          <Title color={colors.darkBlue} fontSize="24px">Usuarios</Title>
          <ListTypeInvestment>
            <Text
              border="1px solid gray"
              margin="5px 10px 5px 0"
              padding="5px 15px"
              borderRadius="5px"
              fontSize="10px"
            >
              <span style={{ margin: '0 10px 0 0', }}><TypeInvestment type="retirement" /></span>Retiro
            </Text>
            <Text
              border="1px solid gray"
              margin="5px 10px 5px 0"
              padding="5px 15px"
              borderRadius="5px"
              fontSize="10px"
            >
              <span style={{ margin: '0 10px 0 0', }}><TypeInvestment type="ten_years" /></span>10 años
            </Text>
            <Text
              border="1px solid gray"
              margin="5px 10px 5px 0"
              padding="5px 15px"
              borderRadius="5px"
              fontSize="10px"
            >
              <span style={{ margin: '0 10px 0 0', }}><TypeInvestment type="fifteen_years" /></span>15 años
            </Text>
            <Text
              border="1px solid gray"
              margin="5px 10px 5px 0"
              padding="5px 15px"
              borderRadius="5px"
              fontSize="10px"
            >
              <span style={{ margin: '0 10px 0 0', }}><TypeInvestment type="twenty_years" /></span>20 años
            </Text>
          </ListTypeInvestment>
          <PoliciesTableContainer>
            <PoliciesTable>
              <MainHeadTable>
                <MainRowTable>
                  <MainColumnTable></MainColumnTable>
                  <MainColumnTable>NOMBRE</MainColumnTable>
                  <MainColumnTable>CORREO</MainColumnTable>
                  <MainColumnTable>TELÉFONO</MainColumnTable>
                  <MainColumnTable>NO. PÓLIZA</MainColumnTable>
                  <MainColumnTable>MONTO</MainColumnTable>
                  <MainColumnTable>RECURRENCIA</MainColumnTable>
                  <MainColumnTable>PAGO</MainColumnTable>
                  <MainColumnTable>VIGENCIA</MainColumnTable>
                  <MainColumnTable>DETALLES</MainColumnTable>
                </MainRowTable>
              </MainHeadTable>
              <BodyTable>
              {
                  policies.length === 0 && (
                    <BodyRowTable key={0}>
                      <BodyColumnTable textAlign="center" whiteSpace="nowrap" colSpan="10">{messageLoading}</BodyColumnTable>
                    </BodyRowTable>
                  )
                }
                {Object.keys(policies).map((el, id) => {
                  const policy = policies[el]
                  return (
                    <BodyRowTable key={id}>
                      <BodyColumnTable background={true}>
                        <TypeInvestment type={policy.cotizacion && policy.cotizacion.typeInvestment}></TypeInvestment>
                      </BodyColumnTable>
                      <BodyColumnTable whiteSpace="nowrap">{policy.name} {policy.lastname}</BodyColumnTable>
                      <BodyColumnTable background={true}>{policy.email}</BodyColumnTable>
                      <BodyColumnTable whiteSpace="nowrap">{policy.lada || policy.phone ? '(' + policy.lada + ') ' + policy.phone : '-'}</BodyColumnTable>
                      <BodyColumnTable background={true}>{policy.idPolicy || '-'}</BodyColumnTable>
                      <BodyColumnTable>{formatNumber(policy && policy.cotizacion && policy.cotizacion.AMAnual)}</BodyColumnTable>
                      <BodyColumnTable>{policy.paymentFrecuency}</BodyColumnTable>
                      <BodyColumnTable background={true}>{policy.application[3] ? policy.application[3].cardType : '-'}</BodyColumnTable>
                      <BodyColumnTable>{policy.vigencia ? moment(policy.vigencia).format('DD/MM/YYYY') : '-'}</BodyColumnTable>
                      <BodyColumnTable background={true}>
                        <BtnPolicyTable to={{ pathname: `/polizas/${policy._id}`, policy, }}>Ver</BtnPolicyTable>
                      </BodyColumnTable>
                    </BodyRowTable>
                  )
                })}
              </BodyTable>
            </PoliciesTable>
          </PoliciesTableContainer>
        </ContainerDashboard>
      </BodyContainerDashboard>
    </DashboardContainerRows>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  notification: state.notification,
})


export default connect(mapStateToProps)(PoliciesAccepted)