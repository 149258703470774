import styled from 'styled-components';
import colors from '../../constants/colors';

export const PromotersTableContainer = styled.section`
  overflow-x: auto;
`;

export const PromotersTable = styled.table`
  width: 100%;
  margin: 1rem auto;
  border-spacing: 0;
`;

export const MainHeadTable = styled.thead``;


export const MainRowTable = styled.tr`
  background: #E1E8EF;
  font-size: 12px;
`;

export const MainColumnTable = styled.th`
  padding: 0.5rem;
  color: ${colors.darkBlue};
  white-space: nowrap;
  text-align: left;
  font-weight: normal;
`;

export const BodyTable = styled.tbody`
`;

export const BodyRowTable = styled.tr`
  background: ${colors.white};
  font-size: 0.8rem;
`;

export const BodyColumnTable = styled.td`
  padding: 1rem 0.5rem;
  border-top: 4px solid #E1E8EF;
  border-bottom: 2px solid #E1E8EF;
  white-space: ${(props) => props.whiteSpace || 'normal' };
  background: ${(props) => props.background ? '#f8fcff' : ''};
`;

export const BtnPromoterTable = styled.button`
  border-radius: 15px;
  padding: 5px 15px;
  text-decoration: none;
  border: 1px solid ${colors.bluedGray};
  color: ${colors.darkBlue};
  cursor: pointer;
  outline: none;
  background: none;
  font-size: 12px;
`;