import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PromotersTable, MainRowTable, MainColumnTable, MainHeadTable, BodyTable, BodyRowTable, BodyColumnTable, BtnPromoterTable, PromotersTableContainer } from '../../pages/promoters/Promoters.styled';
import { deleteAdmin } from '../../requests/admins';

const PromotersList = (props) => {
  // const [adminsList, setAdminsList] = useState([{ "is_team": false, "is_promoter": true, "_id": "5f455ca002356728d33cbe16", "email": "andresdominguezmx@gmail.com", "name": "Andrés", "lastname": "Domínguez", "password": "$2a$10$A84jUH435.whOBi2TlshUevwBz.6Wst65nentg0Rp.gmiAR2ixfWK", "__v": 0 }, { "is_team": false, "is_promoter": true, "_id": "602ac646fb91eb5004fbdee2", "name": "Andres", "lastname": "Dominguez", "email": "andres_gomez@live.com.mx", "company": "602378198fbe233b05f748a9", "updatedAt": "2021-02-15T19:06:46.550Z", "createdAt": "2021-02-15T19:06:46.550Z", "__v": 0 }]);
  const [adminsList, setAdminsList] = useState([])
  
  useEffect(() => {
    if (props.admins) setAdminsList(props.admins)
  }, [props.admins])

  const deleteUser = (id, el) => {
    let confirmDelete = window.confirm("¿Deseas continuar con la eliminación del usuario? Esta acción es permanente y puede haber pérdida de información importante.");
    if (!confirmDelete) return;
    props.func_verify((user) => {
      deleteAdmin(user.token, id)
        .then(() => {
          let prev = adminsList
          prev.splice(el, 1)
          return [...prev]
        })
        .then((res) => {
          console.log(res)
          setAdminsList(res)
        })
        .catch((err) => { console.log(err); alert("Hubo un error al eliminar el usuario.") })
    });
  }
  return (
    <PromotersTableContainer>
      <PromotersTable>
        <MainHeadTable>
          <MainRowTable>
            <MainColumnTable>NOMBRE</MainColumnTable>
            <MainColumnTable>CORREO</MainColumnTable>
            <MainColumnTable>TEAM</MainColumnTable>
            <MainColumnTable>DETALLES</MainColumnTable>
          </MainRowTable>
        </MainHeadTable>
        <BodyTable>
          <AdminList admins={[...adminsList]} deleteUser={deleteUser} />
        </BodyTable>
      </PromotersTable>
    </PromotersTableContainer>
  )
}

const AdminList = ({admins, deleteUser}) => {
  if( admins.length === 0) {
    return (
      <BodyRowTable key={0}>
        <BodyColumnTable colSpan="4">Sin usuarios registrados.</BodyColumnTable>
      </BodyRowTable>
    )
  }
  return admins.map((admin, index) => {
    return (
      <BodyRowTable key={index}>
        <BodyColumnTable whiteSpace="nowrap">{admin.name} {admin.lastname}</BodyColumnTable>
        <BodyColumnTable background={true}>{admin.email}</BodyColumnTable>
        <BodyColumnTable whiteSpace="nowrap">{admin.is_team ? 'Sí' : 'No'}</BodyColumnTable>
        <BodyColumnTable background={true}>
          <BtnPromoterTable onClick={() => deleteUser(admin._id, index) }>Eliminar</BtnPromoterTable>
        </BodyColumnTable>
      </BodyRowTable>
    )
  })
};

const mapStateToProps = (state) => ({
  user: state.user,
  notification: state.notification,
})


export default connect(mapStateToProps)(PromotersList);