import React, { useState, useEffect, useRef } from 'react';
import { Container, BodyContainerDashboard, DashboardContainerRows } from '../../commons/common.styled';
import { Title, Text, TextGrotesque } from '../../commons/typography.styled';
import pdf from '../../assets/images/pdf.svg';
import colors from '../../constants/colors';
import { connect } from 'react-redux'
import { formatNumber, openTab } from '../../utils/utils';
// import { InputGuri, SelectGuri } from '../../components/inputs/inputs';
import { RowInformation, InformationCard, DashboardCard, DashboardCardBody, GuriDashboardContainer, GroupInformation, DownloadPolicy, InputUploadDocument, PDFSvg, GuriInput, ButtonsContainer, Button, PolicyDetails, ContainerFile, DestroyDocument, SelectStatus, StatusContainer, GuriButton } from './policy.styled';
import { getPolicy, deleteDocument, upDocument, updatePolicies, sendMailWelcomeAccepted } from '../../requests/policies';
// import { InputGuriBtn } from '../../components/inputs/inputs.styled';
import moment from 'moment';
import Menu from '../../components/menu/Menu';
import { createGNPinsurancePDF } from '../../requests/docusign';

const DashboardCardInformation = ({ title, attributes, state, updateFields }) => {
  const showTitle = title ? (
    <Title
      fontSize="1rem"
      color={colors.blueBg}
      margin="0 0 15px"
    >
      {title}
    </Title>
  ) : (null);

  const showColumns = (data, index) => {
    if (typeof data.value != "object") {
      return (
        <RowInformation key={index}>
          <Text color={colors.gray} fontWeight="bold">{data.title}</Text>
          {
            state && !data.disabled ? (
              <GuriInput onChange={ev => updateFields(ev, data.root)} type={data.type} defaultValue={data.value} disabled={data.disabled} />
            ) : (
              data.type === 'file' && data.value ? <a href={data.value} target="_blank" rel="noopener noreferrer">Abrir archivo</a> : <Text color={colors.black} fontWeight="bold">{data.formatted || "- - -"}</Text>
            )
          }
        </RowInformation>
      )
    } else if (typeof data.value == "object") {
      console.log(data)
      return (
        <GroupInformation key={index}>
          {data.value.map((data, index) => {
            return showColumns(data, index)
          })}
        </GroupInformation>
      )
    }
  }

  return (
    <InformationCard>
      {showTitle}
      {attributes.map((data, index) => {
        return showColumns(data, index)
      })}
    </InformationCard>
  )
}

const getInvestmentNumber = (typeInvestment = "retirement") => {
  return typeInvestment === 'retirement' ? 'Ahorro a los 65 años:' :
    typeInvestment === 'ten_years' ? 'Ahorro en 10 años:' :
      typeInvestment === 'fifteen_years' ? 'Ahorro en 20 años:' :
        typeInvestment === 'twenty_years' ? 'Ahorro en 20 años:' :
          'Ahorro a los ${}:'
}
const getData = (data) => {
  const { application, cotizacion } = data;
  let initial = {
    fullName: [
      {
        title: 'Nombre:',
        type: 'text',
        disabled: true,
        value: data.name ? data.name : '',
        formatted: data.name ? data.name : '',
        root: '["name"]',
      },
      {
        title: 'Apellido:',
        type: 'text',
        disabled: true,
        value: data.lastname ? data.lastname : '',
        formatted: data.lastname ? data.lastname : '',
        root: '["lastname"]',
      },
      {
        title: 'Segundo apellido:',
        type: 'text',
        disabled: true,
        value: data.application && data.application[0] && data.application[0].secondLastname ? data.application[0].secondLastname : '',
        formatted: data.application && data.application[0] && data.application[0].secondLastname ? data.application[0].secondLastname : '',
        root: '["application"][0]["secondLastname"]',
      }
    ],
    contact: [
      {
        title: 'Email:',
        type: 'email',
        disabled: true,
        value: data.email ? data.email : '',
        formatted: data.email ? data.email : '',
        root: '["email"]',
      },
      {
        title: 'Teléfono:',
        value: [
          {
            title: 'Lada',
            type: 'text',
            disabled: true,
            value: data.lada ? data.lada : undefined,
            formatted: data.lada ? data.lada : undefined,
            root: '["lada"]',
          },
          {
            title: 'Número',
            type: 'text',
            disabled: true,
            value: data.phone ? data.phone : undefined,
            formatted: data.phone ? data.phone : undefined,
            root: '["phone"]',
          },
        ]
      },
      {
        title: 'Genero:',
        type: 'text',
        disabled: true,
        value: data.gender ? data.gender : undefined,
        formatted: data.gender ? data.gender : undefined,
        root: '["gender"]',
      },
      {
        title: 'Fumador?:',
        type: 'text',
        disabled: true,
        value: data.iAm ? data.iAm : undefined,
        formatted: data.iAm ? data.iAm : undefined,
        root: '["iAm"]',
      }
    ],
    address: [
      {
        title: 'Calle:',
        type: 'text',
        disabled: true,
        value: application && application[1] && application[1].street ? application[1].street : '',
        formatted: application && application[1] && application[1].street ? application[1].street : '',
        root: '["application"][1]["street"]',
      },
      {
        title: 'Address line',
        value: [
          {
            title: 'No. Ext:',
            type: 'text',
            disabled: true,
            value: application && application[1] && application[1].exterior ? application[1].exterior : '',
            formatted: application && application[1] && application[1].exterior ? application[1].exterior : '',
            root: '["application"][1]["exterior"]',
          },
          {
            title: 'No. Int:',
            type: 'text',
            disabled: true,
            value: application && application[1] && application[1].interior ? application[1].interior : '',
            formatted: application && application[1] && application[1].interior ? application[1].interior : '',
            root: '["application"][1]["interior"]',
          },
          {
            title: 'Zip Code:',
            type: 'text',
            disabled: true,
            value: application && application[1] && application[1].zip ? application[1].zip : '',
            formatted: application && application[1] && application[1].zip ? application[1].zip : '',
            root: '["application"][1]["zip"]',
          }
        ]
      },
      {
        title: 'Address line 2',
        value: [
          {
            title: 'Ciudad:',
            type: 'text',
            disabled: true,
            value: application && application[1] && application[1].city ? application[1].city : '',
            formatted: application && application[1] && application[1].city ? application[1].city : '',
            root: '["application"][1]["city"]',
          },
          {
            title: 'Colonia:',
            type: 'text',
            disabled: true,
            value: application && application[1] && application[1].colonia ? application[1].colonia : '',
            formatted: application && application[1] && application[1].colonia ? application[1].colonia : '',
            root: '["application"][1]["colonia"]',
          }
        ]
      },
      {
        title: 'Address line 3',
        value: [
          {
            title: 'Estado:',
            type: 'text',
            disabled: true,
            value: application && application[1] && application[1].state ? application[1].state : '',
            formatted: application && application[1] && application[1].state ? application[1].state : '',
            root: '["application"][1]["state"]',
          }
        ]
      }
    ],
    insurance: [
      {
        title: 'Póliza ID:',
        type: 'text',
        value: data && data.idPolicy ? data.idPolicy : '',
        formatted: data && data.idPolicy ? data.idPolicy : '',
        root: "idPolicy",
      },
      {
        title: 'Tipo:',
        type: 'text',
        disabled: true,
        value: cotizacion && cotizacion.typeInvestment ? cotizacion.typeInvestment : '',
        formatted: cotizacion && cotizacion.typeInvestment ? cotizacion.typeInvestment : '',
        root: '["cotizacion"]["typeInvestment"]',
      },
      {
        title: getInvestmentNumber(cotizacion && cotizacion.typeInvestment ? cotizacion.typeInvestment : undefined),
        type: 'text',
        disabled: true,
        value: cotizacion && cotizacion.RTP && cotizacion.RTP[0] ? cotizacion.RTP[0].RTP : undefined,
        formatted: formatNumber(cotizacion && cotizacion.RTP[0] && cotizacion.RTP[0].RTP),
        root: '["cotizacion"]["RTP"]',
      },
      {
        title: 'Suma asegurada:',
        type: 'text',
        disabled: true,
        value: cotizacion && cotizacion.SA ? cotizacion.SA : undefined,
        formatted: formatNumber(cotizacion && cotizacion.SA),
        root: '["cotizacion"]["SA"]',
      },
      {
        title: 'Fecha de inicio:',
        type: 'datetime-local',
        formatted: data && data.startPolicy ? moment(data.startPolicy).format('DD/MM/YYYY') : undefined,
        value: data && data.startPolicy ? data.startPolicy : undefined,
        root: "startPolicy",
      },
      {
        title: 'Vigencia:',
        type: 'datetime-local',
        formatted: data && data.vigencia ? moment(data.vigencia).format('DD/MM/YYYY') : undefined,
        value: data && data.vigencia ? moment(data.vigencia).format() : undefined,
        root: "vigencia",
      },
      {
        title: 'Código de referido:',
        type: 'text',
        disabled: true,
        formatted: data && data.reference ? data.reference : '',
        value: data && data.reference ? data.reference : '',
        root: '["reference"]',
      },
      {
        title: 'Compañía:',
        type: 'text',
        disabled: true,
        formatted: data && data.company ? data.company : '',
        value: data && data.company ? data.company : '',
        root: '["company"]',
      }
    ],
    documents: [
      {
        title: 'Póliza',
        type: 'file',
        formatted: data && data.policyPDF ? data.policyPDF : false,
        value: data && data.policyPDF ? data.policyPDF : false,
      },
      {
        title: 'Condiciones generales',
        type: 'file',
        value: data && data.genCondPDF ? data.genCondPDF : false,
        formatted: data && data.genCondPDF ? data.genCondPDF : false,
      },
      {
        title: 'Cotización',
        type: 'file',
        value: data && data.quotePDF ? data.quotePDF : false,
        formatted: data && data.quotePDF ? data.quotePDF : false,
      }
    ],
    validations: [
      {
        title: 'Tipo de identificación',
        type: 'text',
        value: application && application[3] ? ( application[3].idType ? application[3].idType : undefined) : '',
        formatted: application && application[3] ? ( application[3].idType ? application[3].idType : undefined) : '',
      },
      {
        title: 'Número de identificación',
        type: 'text',
        value: application && application[3] ? ( application[3].idNumber ? application[3].idNumber : undefined) : '',
        formatted: application && application[3] ? ( application[3].idNumber ? application[3].idNumber : undefined) : '',
      },
      {
        title: 'Identificación anverso',
        type: 'file',
        value: application && application[3] ? ( application[3].idFront ? application[3].idFront : undefined) : '',
        formatted: application && application[3] ? ( application[3].idFront ? application[3].idFront : undefined) : '',
      },
      {
        title: 'Identificación reverso',
        type: 'file',
        value: application && application[3] ? ( application[3].idBack ? application[3].idBack : undefined) : '',
        formatted: application && application[3] ? ( application[3].idBack ? application[3].idBack : undefined) : '',
      },
      {
        title: 'Comprobante de domicilio',
        type: 'file',
        value: application && application[3] ? (application[3].addressProof ? application[3].addressProof : undefined) : '',
        formatted: application && application[3] ? (application[3].addressProof ? application[3].addressProof : undefined) : '',
      },
    ],
    beneficiaries: [
    ],
    billing: [
      {
        title: 'Cuenta terminación',
        type: 'text',
        disabled: true,
        value: application && application[3] ? (application[3].CLABE ? application[3].CLABE.replace(/\d{12}(\d{4})/, "************$1") : application[3].cardNumber.replace(/\d{12}(\d{4})/, "************$1")) : '',
        formatted: application && application[3] ? (application[3].CLABE ? application[3].CLABE.replace(/\d{12}(\d{4})/, "************$1") : application[3].cardNumber.replace(/\d{12}(\d{4})/, "************$1")) : ''
      },
      {
        title: 'Cargos:',
        type: 'text',
        disabled: true,
        value: cotizacion && (data.paymentFrecuency === 'Anual' ? cotizacion.AMAnual : cotizacion.AM),
        formatted: formatNumber(cotizacion && (data.paymentFrecuency === 'Anual' ? cotizacion.AMAnual : cotizacion.AM)),
        root: '["cotizacion"]["paymentFrecuency"]',
      },
      {
        title: 'Frecuencia',
        type: 'text',
        disabled: true,
        value: data && data.paymentFrecuency ? data.paymentFrecuency : '',
        formatted: data && data.paymentFrecuency ? data.paymentFrecuency : '',
      },
      {
        title: 'Costo anual:',
        type: 'text',
        value: cotizacion && cotizacion.AMAnual ? cotizacion.AMAnual : undefined,
        formatted: formatNumber(cotizacion && cotizacion.AMAnual),
      }
    ]
  }

  if (application && application[2]) {
    application[2].beneficiaries.forEach((ele) => {
      initial['beneficiaries'].push({
        title: `${ele.percentage} ${ele.name} ${ele.lastname}`,
        type: 'text',
        value: ele.relationship,
        formatted: ele.relationship,
      })
    })
  }
  // console.log(initial)
  return initial;
}

const Policy = (props) => {
  const idPolicy = useRef(props.match.params.id);
  const [data, setData] = useState(getData([]))
  const [policy, setPolicy] = useState({})
  const [policyStatus, setPolicyStatus] = useState(undefined);

  useEffect(() => {
    if (props.location.policy) {
      setData(getData(props.location.policy));
      setPolicy(props.location.policy);
      setPolicyStatus(props.location.policy.status);
    }
    if (!props.location.policy) {
      props.func_verify((user) => {
        getPolicy(user.accessToken, idPolicy.current)
          .then(response => {
            if (response.status !== 200) {
              throw new Error("Ocurrió un error al iniciar sesión.")
            }
            return response
          })
          .then((res) => res.json())
          .then((res) => {
            setPolicy(res.data);
            setPolicyStatus(res.data.status);
            return getData(res.data);
          })
          .then((data) => setData(data))
          .catch((error) => { })
      })
    }
  }, [props])

  const removeDocument = (index) => {
    let field = undefined
    if (index === 0) field = 'policyPDF';
    if (index === 1) field = 'genCondPDF';
    if (index === 2) field = 'quotePDF';

    if (field === undefined) {
      alert("No se pudo eliminar el archivo.")
      return
    }
    let conf = window.confirm("¿Estás seguro en eliminar el documento? Será una acción irreversible.")
    if (conf) {
      props.func_verify((user) => {
        deleteDocument(policy['_id'], user.accessToken, field)
          .then(response => {
            if (response.status !== 200) {
              throw new Error("Ocurrió un error al iniciar sesión.")
            }
            return response
          })
          .then((res) => res.json())
          .then((res) => {
            setPolicy(res.data);
            setPolicyStatus(res.data.status);
            return getData(res.data)
          })
          .then((res) => { setData(res); console.log(data) })
          .catch((error) => { alert("Hubo un error al eliminar el documento") })
      })
    }
  }

  const uploadDocument = (index, ev) => {
    let formData = new FormData();
    if (index === 0) formData.append("field", "policyPDF");
    if (index === 1) formData.append("field", "genCondPDF");
    if (index === 2) formData.append("field", "quotePDF");
    formData.append("file", ev.target.files[0])

    let conf = window.confirm("¿Estás seguro que quieres subir este archivo?");
    if (conf) {
      props.func_verify((user) => {
        upDocument(idPolicy.current, user.accessToken, formData)
          .then(response => {
            if (response.status !== 200) {
              throw new Error("Ocurrió un error al iniciar sesión.")
            }
            return response
          })
          .then((res) => res.json())
          .then((res) => {
            setPolicy(res.data)
            setPolicyStatus(res.data.status);
            return getData(res.data)
          })
          .then((res) => { setData(res); console.log(data) })
          .catch((error) => { console.log(error); alert("Hubo un error al subir el documento") })
      })
    }
  }

  const changeStatusPolicy = (ev) => {
    const status = ev.target.value;
    let conf = window.confirm("¿Estás seguro que quieres actualizar el estatus?");
    if (conf) {
      props.func_verify((user) => {
        updatePolicies(idPolicy.current, user.accessToken, JSON.stringify({ status: status }))
          .then(response => {
            if (response.status !== 200) {
              throw new Error("Ocurrió un error al iniciar sesión.")
            }
            return response
          })
          .then((res) => res.json())
          .then((res) => {
            setPolicy(res.data)
            setPolicyStatus(res.data.status);
            return getData(res.data)
          })
          .then((res) => { setData(res); console.log(data) })
          .catch((error) => { console.log(error); alert("Hubo un error al subir el documento") })
      })
    }
  }

  // const updateDataInput = (ev) => {
  //   let prevData = formData;
  //   prevData[ev.target.name] = ev.target.value;
  //   setFormData(prevData)
  // }


  // const submitPolicy = async (ev, idUserApplication, policyPDF, userToken) => {
  //   ev.preventDefault();
  //   if (policyPDF) {
  //     await saveNewPolicyPDF(idUserApplication, policyPDF, (url) => {
  //       let prevData = formData;
  //       prevData['policyPDF'] = url;
  //       setFormData(prevData)
  //       updatePolicy()
  //     })
  //   } else {
  //     updatePolicy()
  //   }
  // }

  // const updatePolicy = async () => {
  //   await updatePolicies(idPolicy.current, accessToken, JSON.stringify(formData))
  //     .then((res) => alert("Actualizción con éxito"))
  //     .catch((err) => console.log(err))
  // }

  const genDocuSign = () => {
    console.log(policy)
    const urlDocusign = createGNPinsurancePDF(policy, policy.application);
    openTab(urlDocusign);
  }

  const sendWelcomeMail = () => {
    if (!policy.policyPDF || !policy.quotePDF || !policy.genCondPDF) {
      alert("Debes adjuntar la póliza, condiciones generales y cotización antes de poder enviar el correo electrónico.")
      return
    }
    let conf = window.confirm("¿Estás seguro que quieres enviar el mail?");
    if (conf) {
      props.func_verify((user) => {
        sendMailWelcomeAccepted(idPolicy.current, user.accessToken)
          .then((res) => alert("Se envió correctamente el correo de bienvenida."))
          .catch((err) => alert("Hubo un error al enviar el correo."))
      });
    }
  }

  const saveInsuredChanges = () => {
    let conf = window.confirm("¿Estás seguro que quieres actualizar la póliza?");
    if (conf) {
      props.func_verify((user) => {
        updatePolicies(idPolicy.current, user.accessToken, JSON.stringify(stateFieldsUpdated))
          .then(response => {
            if (response.status !== 200) {
              throw new Error("Ocurrió un error al iniciar sesión.")
            }
            return response
          })
          .then((res) => res.json())
          .then((res) => {
            setPolicy(res.data)
            setPolicyStatus(res.data.status);
            setStateInsured(false)
            return getData(res.data)
          })
          .then((res) => { setData(res); console.log(data) })
          .catch((error) => { console.log(error); alert("Hubo un error al actualizar la póliza") })
      })
    }
  }


  const updateFields = (ev, root) => {
    console.log(ev.target.value)
    if (['idPolicy', 'startPolicy', 'vigencia'].includes(root)) {
      setStateFieldsUpdated({
        ...stateFieldsUpdated,
        [root]: ev.target.value,
      })
    }
  }

  const [stateInsured, setStateInsured] = useState(false);
  const [stateFieldsUpdated, setStateFieldsUpdated] = useState({});
  return (
    <DashboardContainerRows>
      <Menu logout={props.dispatch} />
      <BodyContainerDashboard>
        {/* <Header user={props.user} logout={props.dispatch} /> */}
        <Container>
          <Title color={colors.darkBlue} fontSize="24px">Pólizas</Title>
          <GuriDashboardContainer>
            <DashboardCard>
              <DashboardCardBody>
                <Title
                  fullSize
                  fontSize="1.3rem"
                  color={colors.blueBg}>Datos del asegurado
                </Title>
                <PolicyDetails>
                  {policy && policy.idPolicy && (<Text margin="10px 0 0">Póliza #{policy.idPolicy}</Text>)}
                  <StatusContainer>
                    <Text color={colors.blueBg} fontWeight="700">Estatus:</Text>
                    <SelectStatus value={policyStatus} onChange={(ev) => changeStatusPolicy(ev)} policyStatus={policyStatus}>
                      <option disabled value="default">Sin estado asignado</option>
                      <option value="unfinished">Sin completar</option>
                      <option value="cancelled">Cancelado</option>
                      <option value="approved">Aprobado</option>
                      <option value="pending">Pendiente</option>
                    </SelectStatus>
                  </StatusContainer>
                </PolicyDetails>
                <DashboardCardInformation
                  title="Nombre completo"
                  key={1}
                  attributes={data['fullName']}
                  state={stateInsured}
                  updateFields={updateFields}
                />
                <DashboardCardInformation
                  key={2}
                  title="Información de contacto"
                  attributes={data['contact']}
                  state={stateInsured}
                  updateFields={updateFields}
                />
                <DashboardCardInformation
                  key={3}
                  title="Dirección"
                  attributes={data['address']}
                  state={stateInsured}
                  updateFields={updateFields}
                />
                <DashboardCardInformation
                  key={4}
                  title="Póliza"
                  attributes={data['insurance']}
                  state={stateInsured}
                  updateFields={updateFields}
                />

                <ButtonsContainer>
                  {
                    stateInsured && (
                      <>
                        <Button outline={true} background={colors.lightGray} color={colors.black} onClick={() => setStateInsured(false)}>Cancelar</Button>
                        <Button background={colors.blue} onClick={() => saveInsuredChanges()}>Guardar</Button>
                      </>
                    )
                  }
                  {
                    !stateInsured && (
                      <>
                        <Button onClick={() => setStateInsured(true)}>Editar</Button>
                      </>
                    )
                  }
                </ButtonsContainer>
              </DashboardCardBody>
            </DashboardCard>
            <DashboardCard>
              <DashboardCardBody>
                <Title fullSize fontSize="1.3rem" color={colors.blueBg}>
                  Beneficiarios
              </Title>
                <DashboardCardInformation
                  key={5}
                  attributes={data['beneficiaries']}
                />
              </DashboardCardBody>
              <DashboardCardBody>
                <Title fullSize fontSize="1.3rem" color={colors.blueBg}>
                  Información de facturación
                </Title>
                <TextGrotesque fontWeight="600" margin="10px 0 0">
                  Ajustes de facturación
                </TextGrotesque>
                <DashboardCardInformation
                  key={6}
                  attributes={data['billing']}
                />
              </DashboardCardBody>
              <ShowPolicyPDF removeDocument={removeDocument} uploadDocument={uploadDocument} documents={data['documents']} />
              <DashboardCardBody>
                <Title fullSize fontSize="1.3rem" color={colors.blueBg}>
                  Datos adicionales
                </Title>
                <DashboardCardInformation
                  key={7}
                  attributes={data['validations']}
                />
              </DashboardCardBody>
              <DashboardCardBody column={true}>
                <GuriButton disabled onClick={() => { genDocuSign() }}>Generar póliza nuevamente</GuriButton>
                <GuriButton disabled={!policy.genCondPDF || !policy.quotePDF || !policy.policyPDF} onClick={() => { sendWelcomeMail() }}>Enviar correo con los documentos</GuriButton>
              </DashboardCardBody>
            </DashboardCard>
          </GuriDashboardContainer>
        </Container>

      </BodyContainerDashboard>
    </DashboardContainerRows>
  );
}

const ShowPolicyPDF = ({ documents, removeDocument, uploadDocument }) => {
  const [state, setState] = useState(false)
  // if (documents) return null;
  return (
    <DashboardCardBody>
      <Title fullSize fontSize="1.3rem" color={colors.blueBg}>
        Documentos de póliza
      </Title>
      {
        documents.map((el, index) => {
          if (el.value === false && !state) return null
          return (
            <ContainerFile key={index} state={state}>
              <DownloadPolicy href={el.value ? el.value : undefined} download rel="noopener noreferrer" target="_blank">
                <PDFSvg src={pdf} />
                {el.title}
              </DownloadPolicy>
              <InputUploadDocument state={state && el.value === false} type="file" name="file" onChange={(ev) => { uploadDocument(index, ev) }} />
              <DestroyDocument state={state && el.value !== false} onClick={(ev) => { removeDocument(index) }}>Eliminar documento</DestroyDocument>
            </ContainerFile>
          )
        })
      }
      <ButtonsContainer>
        {
          state && (
            <>
              <Button outline={true} background={colors.blue} color={colors.blue} onClick={() => setState(false)}>Salir</Button>
            </>
          )
        }
        {
          !state && (
            <>
              <Button onClick={() => setState(true)}>Editar</Button>
            </>
          )
        }
      </ButtonsContainer>
    </DashboardCardBody>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  notification: state.notification
})
export default connect(mapStateToProps)(Policy);