import styled, { css } from 'styled-components';
import colors from '../../constants/colors';
import mediaQueries from '../../constants/mediaQueries';

export const Container = styled.div`
  width: calc(100% - 40px);
  padding: 0 20px;
  max-width: 1280px;
  margin: 0 auto;
`;

export const GuriFormElement = styled.div`
  flex: 0 0 calc(${props => (props.width || '50%')} - 12.5px);
  ${props => props.fullWidth && css`
    width: 100%;
    flex: 0 0 100%;
  `}
  ${props => props.flex && css`
    flex: ${props.flex};
  `}
  padding-bottom: ${props => (props.paddingBottom || '30px')};
  display: flex;
  align-items: ${props => (props.alignItems || 'flex-end')};
  justify-content: ${props => (props.justifyContent || 'flex-start')};
  flex-wrap: ${props => (props.flexWrap || 'nowrap')};
  margin: ${props => (props.margin || '0')};
  overflow: ${props => (props.overflow || 'hidden')};
  ${mediaQueries.onlyMd}{
    width: 100%;
    flex: 0 0 100%;
  }
  ${mediaQueries.sm}{
    ${props => props.sm && css`
      ${props.sm.fullWidth && css`
        width: 100%;
        flex: 0 0 100%;
      `}
      justify-content: ${props.sm.justifyContent || props.justifyContent || 'flex-start'};
    `}
  }
`;

export const AuthenticateContainer = styled.div`
position: relative;
display: flex;
min-height: 100vh;
&:after{
  content: '';
  position: absolute;
  right: -100px;
  top: 20px;
  height: 200px;
  width: 200px;
  background: ${colors.lightYellow};
  border-radius: 100%;
  ${mediaQueries.sm}{
    right: -50px;
    height: 100px;
    width: 100px;
  }
}
`;

export const AuthenticateInnerContainer = styled(Container)`
display: flex;
flex-direction: column;
height: inherit;
`;

export const AuthenticateNavbar = styled.div`
padding: 50px 0 30px;
img {
  height: 60px;
  width: auto;
  object-fit: contain;
  object-position: center;
  ${mediaQueries.sm}{
    height: 40px;
  }
}
`;

export const AuthenticateForm = styled.div`
display: flex;
padding: 0 60px;
justify-content: space-between;
align-items: ${props => (props.alignItems || 'center')};;
min-height: ${props => (props.minHeight)};;
${mediaQueries.sm}{
  padding: 0 10px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: ${props => ((props.sm && props.sm.direction) || 'column')};
}
`;

export const AuthenticateFormDescription = styled.div`
flex: 0 0 48%;
align-items: ${props => (props.alignItems)};
display: ${props => (props.display)};
flex-direction: ${props => (props.flexDirection)};
justify-content: ${props => (props.justifyContent)};
}
${mediaQueries.sm}{
  flex: 1;
  width: 100%;
}
img{
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  height: auto;
}
`;

export const AuthenticateFormInputs = styled.div`
flex: 0 0 45%;
min-height: ${props => (props.minHeight || '')};
${mediaQueries.sm}{
  flex: 1;
  margin: 40px 0;
  width: 100%;
}
`;