import styled from 'styled-components';
import colors from '../../constants/colors';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width || '100%')};
  margin: ${props => (props.margin || '0.5rem')};
`;

export const InputTitle = styled.p`
  font-weight: 600;
  padding: 0.1rem 0;
  font-size: 0.7rem;
`;

export const InputGuriElement = styled.input`
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid ${colors.lightGray};
  height: 100%;
`;

export const InputGuriBtn = styled.input`
  padding: 0.5rem;
  border-radius: 5px;
  background: none;
  color: ${colors.blue};
  border: 1px solid ${colors.blue};
  cursor: pointer;
  &:hover,&:active {
    background: ${colors.blue};
    color: ${colors.white};
  }
`;

export const SelectGuriElement = styled.select`
  padding: 0.5rem;
  border-radius: 5px;
  height: 100%;
  border: 1px solid ${colors.lightGray};
`;

export const ErrorText = styled.p`
  color: ${props => (props.color || colors.red)};
  margin: 5px 0 10px 10px;
  font-size: 10px;
`;
