import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalContent } from '../../components/modals/modals.styled';
import { AddCompanyForm } from './AddCompany.styled';
import { InputGuri, SelectGuri } from '../../components/inputs/inputs';
import { saveCompanyAPI } from '../../requests/companies';
const AddCompany = (props) => {
  const [data, setData] = useState({})

  const changeInput = (ev) => {
    let prevData = data;
    prevData[ev.target.name] = ev.target.value;
    setData(prevData);
  }

  const saveForm = (ev) => {
    ev.preventDefault();
    saveCompany();
  }

  const saveCompany = () => {
    props.func_verify((user) => {
      saveCompanyAPI(user.accessToken, JSON.stringify(data))
        .then(response => {
          if (response.status !== 201) {
            throw new Error("Ocurrió un error al iniciar sesión.")
          }
          return response
        })
        .then((res) => res.json())
        .then(res => {
          let prevCompanies = props.saveCompany[0];
          prevCompanies[res.data._id] = res.data;
          props.saveCompany[1](prevCompanies);
          props.toogleModal();
        })
        .catch((error) => {
          console.log(error)
          alert("Hubo un error al crear la compaía.")
         })
    })
    return
  }

  return (
    <Modal display={props.status}>
      <ModalBody>
        <ModalHeader>
          <h2>Añadir compañía</h2>
          <span onClick={() => props.toogleModal()}>Cerrar</span>
        </ModalHeader>
        <ModalContent>
          <AddCompanyForm
            method="POST"
            onSubmit={(ev) => saveForm(ev)}
          >
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="name"
              placeholder="Nombre de la compañía"
              title="Nombre"
              margin="0"
              type="text"
              autocomplete="off"
            />
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="email"
              placeholder="Email principal de la empresa (previo registro en DocuSign)"
              title="Email"
              margin="0"
              type="email"
            />
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="agent_name"
              placeholder="Nombre del Agente"
              title="Nombre del Agente"
              margin="0"
              type="text"
            />
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="agent_cua"
              placeholder="CUA del Agente"
              title="CUA del Agente"
              margin="0"
              type="number"
            />
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="agent_contrato"
              placeholder="Contrato del Agente"
              title="Contrato del Agente"
              margin="0"
              type="number"
            />
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="agent_da"
              placeholder="DA Agente"
              title="DA Agente"
              margin="0"
              type="number"
            />
            <InputGuri
              onChange={(ev) => { changeInput(ev) }}
              name="agent_distribution"
              placeholder="Distribución del Agente"
              title="Distribución del Agente"
              margin="0"
              type="number"
            />
            <SelectGuri
              onChange={(ev) => { changeInput(ev) }}
              name="is_partner"
              placeholder="¿Es socio?"
              title="¿Es socio?"
              margin="0"
            >
              <option disabled>Escoge una opción</option>
              <option value="true">Sí, es socio</option>
              <option value="false">No, no es socio</option>
            </SelectGuri>
            <InputGuri
              type="submit"
              name="submit"
              value="Guardar"
              margin="1em 0"
            />
          </AddCompanyForm>
        </ModalContent>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  notification: state.notification,
})


export default connect(mapStateToProps)(AddCompany)