import styled from 'styled-components';
import colors from '../../constants/colors';

export const RefreshLoginModal = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: hsl(0deg 0% 21% / 66%);
  z-index: 1;
`;
export const RefresLoginContainer = styled.div`
  min-width: 300px;
  max-width: 400px;
  padding: 1em;
  background: white;
  border: 1px solid gray;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const RefreshLoginLogo = styled.img`
  width: 100px;
  margin: 10px auto;
`;

export const RefreshLoginErrors = styled.div`
  background: ${colors.red};
  padding: 0.5rem 1rem;
  border-radius: 3px;
  margin: 10px 0;
  p {
    margin: 0;
    padding: 0;
    color: ${colors.white};
    font-size: 12px;
  }
`