import React from 'react';
import { MenuContainer, NavContainer, Nav, Li, Ancla } from './Menu.styled';
import { GuriLogo, LogOutBtn } from '../header/header.styled';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { logout } from '../../actions/userActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Menu = (props) => {
  const dispatch = props.logout;
  return (
    <MenuContainer>
      <div>
        <Link to="/">
          <GuriLogo src={logo} alt="Güri" />
        </Link>
      </div>
      <NavContainer>
        <Nav>
          <Li>
            <Ancla to="/">Inicio</Ancla>
          </Li>
          <Li>
            <Ancla to="/polizas">Pólizas</Ancla>
          </Li>
          <Li>
            <Ancla to="/polizas-aceptadas">Usuarios</Ancla>
          </Li>
          {props.user && props.user.isTeam && (
            <>
              <Li>
                <Ancla to="/companies">Compañías</Ancla>
              </Li>
              <Li>
                <Ancla to="/partners">Socios</Ancla>
              </Li>
            </>
          )}
          {props.user && props.user.isPromoter && (
            <>
              <Li>
                <Ancla to="/solicitudes">Crear solicitud</Ancla>
              </Li>
            </>
          )}
        </Nav>
        <Nav>
          <Li>
            <p style={{margin: 0, fontSize: '14px'}}>Hola, {props.user.name}</p>
            <LogOutBtn onClick={() => { logOut(dispatch, props.history) }}>Cerrar sesión</LogOutBtn>
          </Li>
        </Nav>
      </NavContainer>
    </MenuContainer>
  )
}

const logOut = async (dispatch, history) => {
  await dispatch(logout())
  if (history) history.push('/');
}


function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(withRouter(Menu));